<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div v-if="formtype == 'view'" class="col text-right">
                          <base-button size="sm" type="default" @click="tracking()">{{
                            tt("tracking")
                          }}</base-button>
                        </div>
                        <div v-if="formtype == 'edit'" class="col text-right">
                          <base-button size="sm" type="default" @click="create('b1')">{{
                            tt("add_new")
                          }}</base-button>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('description')" :prop="tt('description')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('detail') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('edit') }}</el-dropdown-item> -->
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'b1'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>
        
        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>

        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>
                    <!-- <base-alert type="danger" v-if="errorMessage">
                        <p><strong>{{ errorMessage.message }}</strong></p>
                        <div v-if="errorMessage.data.length != 0">
                            <span v-for="message in errorMessage.data">
                                {{ message[0] }}<br>
                            </span>
                        </div>
                    </base-alert> -->
                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        
        <modal :show.sync="form.b1.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{tt('add_new')}}</h5>
            </template>

            <div class="card">
                <div class="card-body">
                    <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('description')" :placeholder="tt('description')" v-model="inputDraftListB1.description" rules="required"></base-input>

                    <table class="table align-items-center table-flush table-bordered table-condensed table-sm">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('parameter')}}</th>
                                <th class="text-white">{{tt('category')}}</th>
                                <th class="text-white">{{tt('description')}}</th>
                                <th><base-button size="sm" class="rounded-circle" type="default" @click="addParamsB1"><i class="fa fa-plus"></i></base-button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(pp, key) in paramsB1.parameter" v-bind:key="pp">
                                <td>
                                    <select class="form-control-sm" v-model="paramsB1.parameter[key]">
                                        <option value="">Choose Parameter</option>
                                        <option v-for="p in parameter" v-bind:key="p">{{p}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-control-sm" v-model="paramsB1.category[key]">
                                        <option value="">Choose Plant</option>
                                        <option :value="c" v-for="c in category" v-bind:key="c">{{c}}</option>
                                    </select>
                                </td>
                                <td><input class="form-control form-control-sm" :name="tt('description')" :placeholder="tt('type')" v-model="paramsB1.description[key]" rules="required"></td>
                                <td><base-button v-if="key != 0" size="sm" class="rounded-circle" type="danger" @click="removeParamsB1(key)"><i class="fa fa-minus"></i></base-button></td>
                            </tr>
                        </tbody>
                    </table>
                    <label class="form-control-label mt-4">{{ tt('reason') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('reason')" rules="required">
                        <textarea class="form-control" v-model="inputDraftListB1.reason" rows="5"></textarea>
                    </base-input>
                    <label class="form-control-label mt-4">{{ tt('note') }}</label>
                    <base-input :name="tt('note')">
                        <textarea class="form-control" v-model="inputDraftListB1.po_text_memo" rows="5"></textarea>
                    </base-input>
                </div>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.b1.show = false">{{ tt('close') }}</base-button>
                <base-button v-if="form.b1.type == 'add'" type="primary" @click="saveB1('add')">{{ tt('add') }}</base-button>
                <base-button v-else-if="form.b1.type == 'edit'" type="primary" @click="saveB1('edit')">{{ tt('edit') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeofSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';

    export default {        
        data() {
            return { 
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                },  
                btnSend: {
                    onLoading: false
                },      
                formMcrInformation: {
                    show: false
                },                
                form: {
                    a1: {
                        show: false,
                        disabled: false,
                        type: 'add',
                        btnSave: {
                            onLoading: false
                        }
                    },
                    a2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    a3: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    a4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    b1: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    b2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    b3: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    b4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    b6: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                },
                formDetail: {
                    show: false
                },
                // formMpn: {
                //     show: false
                // },    
                // tableMpn: {
                //     search: '',                    
                //     total: 0,
                //     data: []
                // },  
                mcrType: 'a1',  
                inputDraftList: {

                },
                inputDraftListA1: {
                    mtype_code: '',
                    item_type_code: '',
                    plant_code: '',
                    fab_non_fab: '',
                    uom: '',
                    bom_non_bom: '',
                    item_name: '',
                    specification: '',
                    note: ''
                },
                inputDraftListA2: {
                    material_number: '',
                    client_plant_level: '',
                    item_type_code: '',
                    critical_non_critical: '',
                    bom_non_bom: '',
                    fab_non_fab: '',
                    item_name: '',
                    specification: '',
                    plant_code: '',
                    abc_indicator_code: '',
                    min_stock: '',
                    mrp_type_code: '',
                    max_stock: '',
                    reason: '',
                    note: ''
                },
                inputDraftListA3: {
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_type_code: '',
                    note: '',
                },
                inputDraftListA4: {
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_type_code: '',
                    sales_org_code: '',
                    distr_channel_code: '',
                    warehouse_number: '',
                    storage_type_code: '',
                    reason: '',
                    note: '',
                },
                inputDraftListB1: {
                    description: '',
                    reason: '',
                    po_text_memo: ''
                },
                inputDraftListB2: {
                    item_name: '',
                    item_type_code: '',
                    group_class: '',
                    uom: '',
                    material_type: '',
                    catalog_type: '',
                    material_group: '',
                    fab_non_fab: '',
                    scope_of_supply: '',
                    bom_non_bom: '',
                    note: '',
                    critical_non_critical: '',
                    characteristics: {
                        item_name: [],
                        value: [],
                        checkbox: []
                    }
                },
                inputDraftListB3: {
                    material_number: '',
                    client_or_plant: '',
                    item_name: '',
                    item_type: '',
                    fabric_or_non_fabrication: '',
                    bom_non_bom: '',
                    characteristics: {
                        cinv_code: [],
                        value: [],
                        checkbox: []
                    },
                    po_text_memo: '',
                    reason: '',
                    note: '',
                },
                inputDraftListB4: {
                    material_number: '',
                    description: '',
                    note: '',
                },
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                parameter: {},
                category: {},
                mcrItem: [],
                materialType: {},
                plantCode: {},
                unitOfMeasure: {},
                manufacture: {},
                manrefType: {},
                equipmentCode: {},
                abcIndicator: {},
                mrpTypes: {},
                mrpController: {},
                valuationClass: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                profitCenter: {},
                warehouse: {},
                storageType: {},
                materialNumber: {},
                locationCode: {},
                materialGroup: {},
                catalogType: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                drawingMaster: {},
                standardItemName: [],
                characteristicItemName: [],
                characteristicValue: [],
                lotSize: [],
                procurementType: [],
                specialProcurementType: [],
                scopeOfSupply: [],
                natoGroupClass: [],
                manufacturePartNumber: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberA1: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberA2: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberA3: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberA4: {
                    mpn: [],
                },
                manufacturePartNumberB2: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberB3: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberB4: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                equipmentQuantityInstall: {
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallA1: {
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallA2: {
                    plant: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallA3: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallA4: {
                    equipment_code: [],
                },
                equipmentQuantityInstallB2: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallB3: {
                    plant: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallB4: {
                    plant: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                attachmentDocument: {
                    file_name: [],
                    file_type: [],
                },
                attachmentDocumentA1: {
                    file_attachment_name: [],
                },
                attachmentDocumentB2: {
                    file_attachment_code: [],
                },
                paramsB1: {
                    parameter: [],
                    category: [],
                    description: []
                },
                plantLevel: {
                    plant: []
                },
                plantLevelB2: {
                    plant: []
                },
                plantLevelB3: {
                    plant: []
                },
                plantLevelB4: {
                    plant: []
                },
                storageLocation: {
                    plant: [],
                    storage_location: [],
                    warehouse_number: [],
                    storage_type: [],
                    bin_location: [],
                },
                tempSales: {
                    sales_org: '',
                    distr_channel: '',
                    division: '',
                    tax_cat: '',
                    tax_class: '',
                    gen_item_cat_grp: '',
                    account_assign_grp: '',
                    item_cat_grp: '',
                    trans_group: '',
                    loading_group: '',
                    profit_center: '',
                },
                sales: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                salesB2: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                salesB3: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                salesB4: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                tempMrp: {
                    abc_indicator: '',
                    mrp_type: '',
                    mrp_controller: '',
                    lot_size: '',
                    min: '',
                    max: '',
                    procurement_type: '',
                    special_proc: '',
                },
                mrp: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                mrpB2: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                mrpB3: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                mrpB4: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                tempAccounting: {
                    valuation_class: '',
                    valuation_category: '',
                    valuation_type: '',
                    price_determination: '',
                    price_unit: '',
                },
                accounting_valuation_class: [],
                accounting_valuation_category: [],
                accounting_valuation_type: [],
                accounting_price_determination: [],
                accounting_price_unit: [],
                accounting: {
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_determination: [],
                    price_unit: [],
                },
                accountingB2: {
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    price_unit: [],
                },
                accountingB3: {
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    price_unit: [],
                },
                accountingB4: {
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    price_unit: [],
                },
                purchasingB4: {
                    plant: [],
                    purchasing_group: [],
                    order_unit: [],
                },
                keywordB2: {
                    keyword: []
                },
                accordion: {
                    a1: {
                        bds: {
                            show: 'show'
                        },
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                        ad: {
                            show: ''
                        },
                    },
                    a2: {
                        cl: {
                            show: 'show'
                        },
                        pl: {
                            show: 'show'
                        },
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                    a3: {
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                    a4: {
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype: window.location.hash.split("/")[5],
                total_mcr_item: '',
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            /*this.getMaterialType()//a1
            this.getPlantCode()//a1,a4
            this.getUnitOfMeasure()//a1
            this.getManufacture()//a1
            this.getManrefType()//a1
            this.getEquipmentCode()//a1
            this.getAbcIndicator()//a2
            this.getMrpTypes()//a2
            this.getMrpController()//b2
            this.getValuationClass()//b2
            this.getValuationCategory()//b2
            this.getValuationType()//a3,a4
            this.getPriceControl()//b2
            this.getSalesOrganization()//a4
            this.getDistributionChannel()//a4
            this.getDivision()//b2
            this.getTaxClassification()//b2
            this.getItemCategoryGroup()//b2
            this.getAccountAssignmentGroup()//b2
            this.getTransportationGroup()//b2
            this.getLoadingGroup()//b2
            this.getProfitCenter()//b2
            this.getWarehouse()//a4
            this.getStorageType()//a4
            //this.getMaterialNumber()//a1,a4
            this.getLocationCode()//a3,a4
            // this.getCatalogType()
            this.getMaterialGroup()
            this.getDrawingMaster()//a1
            this.getStandardItemName()//b2
            this.getLotSize()//b2
            this.getProcurementType()//b2
            this.getSpecialProcurementType()//b2*/
        },
        methods: {
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject;   
                    context.total_mcr_item = response.data.data.mcr_item.length;                   
                })
                .call()
            },
            getMaterialType() {
                let context = this;               
                Api(context, materialType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.plantCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getUnitOfMeasure() {
                let context = this;               
                Api(context, unitOfMeasure.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.unitOfMeasure = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.unitOfMeasure = [];
                    }
                })
                .call()
            },
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manufacture = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                    }
                })
                .call()
            },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getMrpController() {
                let context = this;               
                Api(context, mrpController.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpController = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpController = [];
                    }
                })
                .call()
            },
            getValuationClass() {
                let context = this;               
                Api(context, valuationClass.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationClass = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            getValuationType() {
                let context = this;               
                Api(context, valuationType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                })
                .call()
            },
            getPriceControl() {
                let context = this;               
                Api(context, priceControl.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.priceControl = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = [];
                    }
                })
                .call()
            },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            getProfitCenter() {
                let context = this;               
                Api(context, profitCenter.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.profitCenter = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.profitCenter = [];
                    }
                })
                .call()
            },
            getWarehouse() {
                let context = this;               
                Api(context, warehouse.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.warehouse = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.warehouse = [];
                    }
                })
                .call()
            },
            getStorageType() {
                let context = this;               
                Api(context, storageType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.storageType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.storageType = [];
                    }
                })
                .call()
            },
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialNumber = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            getLocationCode() {
                let context = this;               
                Api(context, locationCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.locationCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                    }
                })
                .call()
            },
            getCatalogType() {
                let context = this;               
                Api(context, catalogType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.catalogType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                })
                .call()
            },
            getMaterialGroup() {
                let context = this;               
                Api(context, materialGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialGroup = [];
                    }
                })
                .call()
            },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            getStandardItemName() {
                let context = this;               
                Api(context, standardItemName.get()).onSuccess(function(response) {    
                    context.standardItemName = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.standardItemName = [];
                    }
                })
                .call()
            },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get()).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            getSpecialProcurementType() {
                let context = this;               
                Api(context, specialProcurementType.get()).onSuccess(function(response) {    
                    context.specialProcurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.specialProcurementType = [];
                    }
                })
                .call()
            },
            getScopeOfSupply() {
                let context = this;               
                Api(context, scopeOfSupply.get()).onSuccess(function(response) {    
                    context.scopeOfSupply = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.scopeOfSupply = [];
                    }
                })
                .call()
            },
            getNatoGroupClass() {
                let context = this;               
                Api(context, natoGroupClass.get()).onSuccess(function(response) {    
                    context.natoGroupClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.natoGroupClass = [];
                    }
                })
                .call()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;
                
                    if (data.btn == 'detail') {
                        context.form.b1.show = true;
                        context.form.b1.disabled = true;
                        context.form.b1.type = 'detail';
                    } else {
                        context.form.b1.show = true;
                        context.form.b1.disabled = false;
                        context.form.b1.type = 'edit';
                    }

                    context.inputDraftListB1.description = [];
                    context.inputDraftListB1.reason = [];
                    context.inputDraftListB1.po_text_memo = [];
                    context.paramsB1.parameter = [];
                    context.paramsB1.category = [];
                    context.paramsB1.description =  [];

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListB1.id = response.data.data[0].id;
                        context.inputDraftListB1.description = response.data.data[0].specification;
                        context.inputDraftListB1.reason = response.data.data[0].reason;
                        context.inputDraftListB1.po_text_memo = response.data.data[0].note;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListB1 = [];
                        }
                    }).call()
                    
                    Api(context, draftList.getMcrItemParameter(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.paramsB1.parameter[i] = response.data.data[i].parameter;
                            context.paramsB1.category[i] = response.data.data[i].category;
                            context.paramsB1.description[i] = response.data.data[i].description;
                            // alert(response.data.data[i].parameter)
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.paramsB1.parameter = [];
                            context.paramsB1.category = [];
                            context.paramsB1.description = [];
                        }
                    }).call()

                    this.form.b1.show = true;
                
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            create(key) {
                if (key == 'MCR01') {
                    key = 'a1';
                } else if (key == 'MCR02') {
                    key = 'a2';
                } else if (key == 'MCR03') {
                    key = 'a3';
                } else if (key == 'MCR04') {
                    key = 'a4';
                }

                if (key == 'a1') {
                    this.inputDraftListA1.mtype_code = '';
                    this.inputDraftListA1.item_type_code = '';
                    this.inputDraftListA1.plant_code = '';
                    this.inputDraftListA1.fab_non_fab = '';
                    this.inputDraftListA1.uom = '';
                    this.inputDraftListA1.bom_non_bom = '';
                    this.inputDraftListA1.item_name = '';
                    this.inputDraftListA1.specification = '';
                    this.inputDraftListA1.note = '';

                    this.manufacturePartNumberA1.manufacture_code = [];
                    this.manufacturePartNumberA1.mpn = [];
                    this.manufacturePartNumberA1.manufacture_type = [];
                    this.manufacturePartNumberA1.manufacture_note = [];

                    this.equipmentQuantityInstallA1.equipment_code = [];
                    this.equipmentQuantityInstallA1.qty_installed = [];
                    this.equipmentQuantityInstallA1.drawing_number = [];

                    this.attachmentDocumentA1.file_attachment_name = [];
                    
                    this.getMaterialType();
                    this.getPlantCode();
                    this.getUnitOfMeasure();
                    this.getManufacture();
                    this.getManrefType();
                    this.getEquipmentCode();                
                                       
                    this.$refs.formA1.reset();                                       
                    this.form.a1.show = true;
                    this.form.a1.disabled = false;
                    this.form.a1.type = 'add';
                } else if (key == 'a2') {
                    this.inputDraftListA2.material_number = '';
                    this.inputDraftListA2.client_plant_level = 'client';
                    this.inputDraftListA2.item_type_code = '';
                    this.inputDraftListA2.critical_non_critical = '';
                    this.inputDraftListA2.bom_non_bom = '';
                    this.inputDraftListA2.fab_non_fab = '';
                    this.inputDraftListA2.item_name = '';
                    this.inputDraftListA2.specification = '';
                    this.manufacturePartNumberA2.manufacture_code = [];
                    this.manufacturePartNumberA2.mpn = [];
                    this.manufacturePartNumberA2.manufacture_type = [];
                    this.manufacturePartNumberA2.manufacture_note = [];
                    this.equipmentQuantityInstallA2.plant = [];
                    this.equipmentQuantityInstallA2.equipment_code = [];
                    this.equipmentQuantityInstallA2.qty_installed = [];
                    this.equipmentQuantityInstallA2.drawing_number = [];
                    this.inputDraftListA2.plant_code = '';
                    this.inputDraftListA2.abc_indicator = '';
                    this.inputDraftListA2.min_stock = '';
                    this.inputDraftListA2.mrp_types = '';
                    this.inputDraftListA2.max_stock = '';
                    this.inputDraftListA2.reason = '';
                    this.inputDraftListA2.note = '';

                    this.form.a2.show = true;
                    this.form.a2.disabled = false;
                    this.form.a2.type = 'add';
                } else if (key == 'a3') {
                    this.inputDraftListA3.material_number = '';
                    this.inputDraftListA3.plant_code = '';
                    this.inputDraftListA3.location_code = '';
                    this.inputDraftListA3.valuation_type_code = '';
                    this.manufacturePartNumberA3.manufacture_code = [];
                    this.manufacturePartNumberA3.mpn = [];
                    this.manufacturePartNumberA3.manufacture_type = [];
                    this.manufacturePartNumberA3.manufacture_note = [];
                    this.equipmentQuantityInstallA3.plant = [];
                    this.equipmentQuantityInstallA3.equipment_code = [];
                    this.equipmentQuantityInstallA3.qty_installed = [];
                    this.equipmentQuantityInstallA3.drawing_number = [];
                    this.inputDraftListA3.note = '';

                    this.form.a3.show = true;
                    this.form.a3.disabled = false;
                    this.form.a3.type = 'add';
                } else if (key == 'a4') {
                    this.inputDraftListA4.material_number = '';
                    this.inputDraftListA4.plant_code = '';
                    this.inputDraftListA4.location_code = '';
                    this.inputDraftListA4.valuation_type_code = '';
                    this.inputDraftListA4.sales_org_code = '';
                    this.inputDraftListA4.distr_channel_code = '';
                    this.inputDraftListA4.warehouse_number = '';
                    this.inputDraftListA4.storage_type_code = '';
                    this.manufacturePartNumberA4.mpn = [];
                    this.equipmentQuantityInstallA4.equipment_code = [];
                    this.inputDraftListA4.reason = '';
                    this.inputDraftListA4.note = '';

                    this.form.a4.show = true;
                    this.form.a4.disabled = false;
                    this.form.a4.type = 'add';
                } else if (key == 'b1') {
                    this.inputDraftListB1.description = '';
                    this.paramsB1.parameter[0] = '';
                    this.paramsB1.category[0] = '';
                    this.paramsB1.description[0] = '';
                    this.inputDraftListB1.reason = '';
                    this.inputDraftListB1.po_text_memo = '';

                    this.form.b1.show = true;
                } else if (key == 'b2') {
                    this.inputDraftListB2.item_name = '';
                    this.inputDraftListB2.group_class ='';
                    this.inputDraftListB2.uom ='';
                    this.inputDraftListB2.material_type ='';
                    this.inputDraftListB2.catalog_type ='';
                    this.inputDraftListB2.material_group ='';
                    this.inputDraftListB2.fab_non_fab ='';
                    this.inputDraftListB2.scope_of_supply ='';
                    this.inputDraftListB2.bom_non_bom ='';
                    this.inputDraftListB2.item_type ='';
                    this.inputDraftListB2.critical_non_critical ='';
                    this.inputDraftListB2.characteristics = {
                        item_name: [],
                        value: [],
                        checkbox: []
                    };

                    this.getStandardItemName();
                    this.getUnitOfMeasure();
                    this.getMaterialType();
                    this.getCatalogType();
                    this.getMaterialGroup();
                    this.getManufacture();
                    this.getManrefType();
                    this.getPlantCode();
                    this.getSalesOrganization();
                    this.getDistributionChannel();
                    this.getDivision();
                    this.getTaxClassification();
                    this.getItemCategoryGroup();
                    this.getAccountAssignmentGroup();
                    this.getTransportationGroup();
                    this.getLoadingGroup();
                    this.getProfitCenter();
                    this.getAbcIndicator();
                    this.getMrpTypes();
                    this.getMrpController();
                    this.getLotSize();
                    this.getProcurementType();
                    this.getSpecialProcurementType();
                    this.getValuationClass();
                    this.getValuationCategory();
                    this.getValuationType();
                    this.getPriceControl();
                    this.getEquipmentCode();
                    this.getScopeOfSupply();
                    this.getNatoGroupClass();

                    this.form.b2.show = true;
                } else if (key == 'b3') {
                    this.inputDraftListB3.material_number = '';
                    this.inputDraftListB3.client_or_plant = '';
                    this.inputDraftListB3.item_name = '';
                    this.inputDraftListB3.item_type = '';
                    this.inputDraftListB3.fabric_non_fabrication = '';
                    this.inputDraftListB3.bom_non_bom = '';
                    this.inputDraftListB3.po_text_memo = '';
                    this.inputDraftListB3.reason = '';
                    this.inputDraftListB3.note = '';

                    this.getMaterialNumber();
                    this.getPlantCode();
                    this.getStandardItemName();
                    // this.getItemType();
                    this.getManufacture();
                    this.getManrefType();
                    this.getEquipmentCode()
                    this.getSalesOrganization();
                    this.getDistributionChannel();
                    this.getDivision();
                    this.getTaxClassification();
                    this.getItemCategoryGroup();
                    this.getAccountAssignmentGroup();
                    this.getTransportationGroup();
                    this.getLoadingGroup();
                    this.getProfitCenter();
                    this.getAbcIndicator();
                    this.getMrpTypes();
                    this.getMrpController();
                    this.getLotSize();
                    this.getProcurementType();
                    this.getSpecialProcurementType();
                    this.getValuationClass();
                    this.getValuationCategory();
                    this.getValuationType();
                    this.getPriceControl();


                    this.form.b3.show = true;
                } else if (key == 'b4') {
                    this.form.b4.show = true;
                } else if (key == 'b6') {
                    this.form.b6.show = true;
                }
            },
            addParamsB1() {
                this.paramsB1.parameter.push('');
                this.paramsB1.category.push('');
                this.paramsB1.description.push('');
            },
            removeParamsB1(key) {
                this.paramsB1.parameter.splice(key,1)
                this.paramsB1.category.splice(key,1)
                this.paramsB1.description.splice(key,1)
            },
            addMpnA1() {
                if (this.manufacturePartNumberA1.manufacture_code.length == 0) {
                    this.manufacturePartNumberA1.manufacture_code.push('')
                    this.manufacturePartNumberA1.mpn.push('')
                    this.manufacturePartNumberA1.manufacture_type.push('')
                    this.manufacturePartNumberA1.manufacture_note.push('')
                } else if (this.manufacturePartNumberA1.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA1.manufacture_code[this.manufacturePartNumberA1.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA1.mpn[this.manufacturePartNumberA1.mpn.length-1] != '' &&
                        this.manufacturePartNumberA1.manufacture_type[this.manufacturePartNumberA1.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberA1.manufacture_note[this.manufacturePartNumberA1.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberA1.manufacture_code.push('')
                        this.manufacturePartNumberA1.mpn.push('')
                        this.manufacturePartNumberA1.manufacture_type.push('')
                        this.manufacturePartNumberA1.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: this.tt('please_complete_data', {'title': this.tt('mpn')}),                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA1(key) {
                this.manufacturePartNumberA1.manufacture_code.splice(key, 1)
                this.manufacturePartNumberA1.mpn.splice(key, 1)
                this.manufacturePartNumberA1.manufacture_type.splice(key, 1)
                this.manufacturePartNumberA1.manufacture_note.splice(key, 1)
            },
            addMpnA2() {
                if (this.manufacturePartNumberA2.manufacture_code.length == 0) {
                    this.manufacturePartNumberA2.manufacture_code.push('')
                    this.manufacturePartNumberA2.mpn.push('')
                    this.manufacturePartNumberA2.manufacture_type.push('')
                    this.manufacturePartNumberA2.manufacture_note.push('')
                } else if (this.manufacturePartNumberA2.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA2.manufacture_code[this.manufacturePartNumberA2.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA2.mpn[this.manufacturePartNumberA2.mpn.length-1] != '' &&
                        this.manufacturePartNumberA2.manufacture_type[this.manufacturePartNumberA2.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberA2.manufacture_note[this.manufacturePartNumberA2.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberA2.manufacture_code.push('')
                        this.manufacturePartNumberA2.mpn.push('')
                        this.manufacturePartNumberA2.manufacture_type.push('')
                        this.manufacturePartNumberA2.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA2(key) {
                this.manufacturePartNumber.manufacture_code.splice(key, 1)
                this.manufacturePartNumber.mpn.splice(key, 1)
                this.manufacturePartNumber.manufacture_type.splice(key, 1)
                this.manufacturePartNumber.manufacture_note.splice(key, 1)
            },
            addMpnA3() {
                if (this.manufacturePartNumberA3.manufacture_code.length == 0) {
                    this.manufacturePartNumberA3.manufacture_code.push('')
                    this.manufacturePartNumberA3.mpn.push('')
                    this.manufacturePartNumberA3.manufacture_type.push('')
                    this.manufacturePartNumberA3.manufacture_note.push('')
                } else if (this.manufacturePartNumberA3.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA3.manufacture_code[this.manufacturePartNumberA3.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA3.mpn[this.manufacturePartNumberA3.mpn.length-1] != '' &&
                        this.manufacturePartNumberA3.manufacture_type[this.manufacturePartNumberA3.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberA3.manufacture_note[this.manufacturePartNumberA3.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberA3.manufacture_code.push('')
                        this.manufacturePartNumberA3.mpn.push('')
                        this.manufacturePartNumberA3.manufacture_type.push('')
                        this.manufacturePartNumberA3.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA3(key) {
                this.manufacturePartNumberA3.manufacture_code.splice(key, 1)
                this.manufacturePartNumberA3.mpn.splice(key, 1)
                this.manufacturePartNumberA3.manufacture_type.splice(key, 1)
                this.manufacturePartNumberA3.manufacture_note.splice(key, 1)
            },
            addMpnA4() {
                if (this.manufacturePartNumberA4.mpn.length == 0) {
                    this.manufacturePartNumberA4.mpn.push('')
                } else if (this.manufacturePartNumberA4.mpn.length > 0) {
                    if (this.manufacturePartNumberA4.mpn[this.manufacturePartNumberA4.mpn.length-1] != '') {
                        
                        this.manufacturePartNumberA4.mpn.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA4(key) {
                this.manufacturePartNumberA4.mpn.splice(key, 1)
            },
            addMpnB2() {
                if (this.manufacturePartNumberB2.manufacture_code.length == 0) {
                    this.manufacturePartNumberB2.manufacture_code.push('')
                    this.manufacturePartNumberB2.mpn.push('')
                    this.manufacturePartNumberB2.manufacture_type.push('')
                    this.manufacturePartNumberB2.manufacture_note.push('')
                } else if (this.manufacturePartNumberB2.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB2.manufacture_code[this.manufacturePartNumberB2.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB2.mpn[this.manufacturePartNumberB2.mpn.length-1] != '' &&
                        this.manufacturePartNumberB2.manufacture_type[this.manufacturePartNumberB2.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB2.manufacture_note[this.manufacturePartNumberB2.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB2.manufacture_code.push('')
                        this.manufacturePartNumberB2.mpn.push('')
                        this.manufacturePartNumberB2.manufacture_type.push('')
                        this.manufacturePartNumberB2.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnB2(key) {
                this.manufacturePartNumberB2.manufacture_code.splice(key, 1)
                this.manufacturePartNumberB2.mpn.splice(key, 1)
                this.manufacturePartNumberB2.manufacture_type.splice(key, 1)
                this.manufacturePartNumberB2.manufacture_note.splice(key, 1)
            },
            addMpnB3() {
                if (this.manufacturePartNumberB3.manufacture_code.length == 0) {
                    this.manufacturePartNumberB3.manufacture_code.push('')
                    this.manufacturePartNumberB3.mpn.push('')
                    this.manufacturePartNumberB3.manufacture_type.push('')
                    this.manufacturePartNumberB3.manufacture_note.push('')
                } else if (this.manufacturePartNumberB3.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB3.manufacture_code[this.manufacturePartNumberB3.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB3.mpn[this.manufacturePartNumberB3.mpn.length-1] != '' &&
                        this.manufacturePartNumberB3.manufacture_type[this.manufacturePartNumberB3.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB3.manufacture_note[this.manufacturePartNumberB3.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB3.manufacture_code.push('')
                        this.manufacturePartNumberB3.mpn.push('')
                        this.manufacturePartNumberB3.manufacture_type.push('')
                        this.manufacturePartNumberB3.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnB3(key) {
                this.manufacturePartNumberB3.manufacture_code.splice(key, 1)
                this.manufacturePartNumberB3.mpn.splice(key, 1)
                this.manufacturePartNumberB3.manufacture_type.splice(key, 1)
                this.manufacturePartNumberB3.manufacture_note.splice(key, 1)
            },
            addMpn() {
                this.manufacturePartNumber.manufacture_code.push('')
                this.manufacturePartNumber.mpn.push('')
                this.manufacturePartNumber.manufacture_type.push('')
                this.manufacturePartNumber.manufacture_note.push('')
            },
            removeMpn(key) {
                this.manufacturePartNumber.manufacture_code.splice(key, 1)
                this.manufacturePartNumber.mpn.splice(key, 1)
                this.manufacturePartNumber.manufacture_type.splice(key, 1)
                this.manufacturePartNumber.manufacture_note.splice(key, 1)
            },
            addEqi() {
                this.equipmentQuantityInstall.equipment_code.push('')
                this.equipmentQuantityInstall.qty_installed.push('')
                this.equipmentQuantityInstall.drawing_number.push('')
            },
            removeEqi(key) {
                this.equipmentQuantityInstall.equipment_code.splice(key, 1)
                this.equipmentQuantityInstall.qty_installed.splice(key, 1)
                this.equipmentQuantityInstall.drawing_number.splice(key, 1)
            },
            addEqiA1() {
                if (this.equipmentQuantityInstallA1.equipment_code.length == 0) {
                    this.equipmentQuantityInstallA1.equipment_code.push('')
                    this.equipmentQuantityInstallA1.qty_installed.push('')
                    this.equipmentQuantityInstallA1.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA1.equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallA1.equipment_code[this.equipmentQuantityInstallA1.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA1.qty_installed[this.equipmentQuantityInstallA1.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA1.drawing_number[this.equipmentQuantityInstallA1.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA1.equipment_code.push('')
                        this.equipmentQuantityInstallA1.qty_installed.push('')
                        this.equipmentQuantityInstallA1.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: this.tt('please_complete_data', {'title': this.tt('equipment_&_quantity_install')}),                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA1(key) {
                this.equipmentQuantityInstallA1.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallA1.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallA1.drawing_number.splice(key, 1)                
            },
            addEqiA2() {
                if (this.equipmentQuantityInstallA2.plant.length == 0) {
                    this.equipmentQuantityInstallA2.plant.push('')
                    this.equipmentQuantityInstallA2.equipment_code.push('')
                    this.equipmentQuantityInstallA2.qty_installed.push('')
                    this.equipmentQuantityInstallA2.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA2.plant.length > 0) {
                    if (this.equipmentQuantityInstallA2.plant[this.equipmentQuantityInstallA2.plant.length-1] != '' &&
                        this.equipmentQuantityInstallA2.equipment_code[this.equipmentQuantityInstallA2.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA2.qty_installed[this.equipmentQuantityInstallA2.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA2.drawing_number[this.equipmentQuantityInstallA2.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA2.plant.push('')
                        this.equipmentQuantityInstallA2.equipment_code.push('')
                        this.equipmentQuantityInstallA2.qty_installed.push('')
                        this.equipmentQuantityInstallA2.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA2(key) {
                this.equipmentQuantityInstallA2.plant.splice(key, 1)
                this.equipmentQuantityInstallA2.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallA2.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallA2.drawing_number.splice(key, 1)                
            },
            addEqiA3() {
                if (this.equipmentQuantityInstallA3.plant.length == 0) {
                    this.equipmentQuantityInstallA3.plant.push('')
                    this.equipmentQuantityInstallA3.equipment_code.push('')
                    this.equipmentQuantityInstallA3.qty_installed.push('')
                    this.equipmentQuantityInstallA3.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA3.plant.length > 0) {
                    if (this.equipmentQuantityInstallA3.plant[this.equipmentQuantityInstallA3.plant.length-1] != '' &&
                        this.equipmentQuantityInstallA3.equipment_code[this.equipmentQuantityInstallA3.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA3.qty_installed[this.equipmentQuantityInstallA3.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA3.drawing_number[this.equipmentQuantityInstallA3.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA3.plant.push('')
                        this.equipmentQuantityInstallA3.equipment_code.push('')
                        this.equipmentQuantityInstallA3.qty_installed.push('')
                        this.equipmentQuantityInstallA3.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA3(key) {
                this.equipmentQuantityInstallA3.plant.splice(key, 1)
                this.equipmentQuantityInstallA3.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallA3.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallA3.drawing_number.splice(key, 1)                
            },
            addEqiA4() {
                if (this.equipmentQuantityInstallA4.equipment_code.length == 0) {
                    this.equipmentQuantityInstallA4.equipment_code.push('')
                } else if (this.equipmentQuantityInstallA4.equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallA4.equipment_code[this.equipmentQuantityInstallA4.equipment_code.length-1] != '') {
                        
                        this.equipmentQuantityInstallA4.equipment_code.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA4(key) {
                this.equipmentQuantityInstallA4.equipment_code.splice(key, 1)                
            },
            addEqiB2() {
                if (this.equipmentQuantityInstallB2.plant.length == 0) {
                    this.equipmentQuantityInstallB2.plant.push('')
                    this.equipmentQuantityInstallB2.equipment_code.push('')
                    this.equipmentQuantityInstallB2.qty_installed.push('')
                    this.equipmentQuantityInstallB2.drawing_number.push('')
                } else if (this.equipmentQuantityInstallB2.plant.length > 0) {
                    if (this.equipmentQuantityInstallB2.plant[this.equipmentQuantityInstallB2.plant.length-1] != '' &&
                        this.equipmentQuantityInstallB2.equipment_code[this.equipmentQuantityInstallB2.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB2.qty_installed[this.equipmentQuantityInstallB2.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallB2.drawing_number[this.equipmentQuantityInstallB2.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallB2.plant.push('')
                        this.equipmentQuantityInstallB2.equipment_code.push('')
                        this.equipmentQuantityInstallB2.qty_installed.push('')
                        this.equipmentQuantityInstallB2.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiB2(key) {
                this.equipmentQuantityInstallB2.plant.splice(key, 1)
                this.equipmentQuantityInstallB2.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB2.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallB2.drawing_number.splice(key, 1)                
            },
            addEqiB3() {
                if (this.equipmentQuantityInstallB3.plant.length == 0) {
                    this.equipmentQuantityInstallB3.plant.push('')
                    this.equipmentQuantityInstallB3.equipment_code.push('')
                    this.equipmentQuantityInstallB3.qty_installed.push('')
                    this.equipmentQuantityInstallB3.drawing_number.push('')
                } else if (this.equipmentQuantityInstallB3.plant.length > 0) {
                    if (this.equipmentQuantityInstallB3.plant[this.equipmentQuantityInstallB3.plant.length-1] != '' &&
                        this.equipmentQuantityInstallB3.equipment_code[this.equipmentQuantityInstallB3.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB3.qty_installed[this.equipmentQuantityInstallB3.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallB3.drawing_number[this.equipmentQuantityInstallB3.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallB3.plant.push('')
                        this.equipmentQuantityInstallB3.equipment_code.push('')
                        this.equipmentQuantityInstallB3.qty_installed.push('')
                        this.equipmentQuantityInstallB3.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiB3(key) {
                this.equipmentQuantityInstallB3.plant.splice(key, 1)
                this.equipmentQuantityInstallB3.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB3.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallB3.drawing_number.splice(key, 1)                
            },
            addAdA1() {
                if (this.attachmentDocumentA1.file_attachment_name.length == 0) {
                    this.attachmentDocumentA1.file_attachment_name.push('')
                } else if (this.attachmentDocumentA1.file_attachment_name.length > 0) {
                    if (this.attachmentDocumentA1.file_attachment_name[this.attachmentDocumentA1.file_attachment_name.length-1] == '') {
                        this.$notify({
                            message: this.tt('please_complete_data', {'title': this.tt('attachment_document')}),                  
                            type: 'danger'
                        });
                    } else {
                        this.attachmentDocumentA1.file_attachment_name.push('')
                    }
                }
            },
            removeAdA1(key) {
                this.attachmentDocumentA1.file_attachment_name.splice(key, 1)
            },
            addAdB2() {
                if (this.attachmentDocumentB2.file_attachment_code.length == 0) {
                    this.attachmentDocumentB2.file_attachment_code.push('')
                } else if (this.attachmentDocumentB2.file_attachment_code.length > 0) {
                    if (this.attachmentDocumentB2.file_attachment_code[this.attachmentDocumentB2.file_attachment_code.length-1] == '') {
                        this.$notify({
                            message: 'Please choose image',                  
                            type: 'danger'
                        });
                    } else {
                        this.attachmentDocumentB2.file_attachment_code.push('')
                    }
                }
            },
            removeAdB2(key) {
                this.attachmentDocumentB2.file_attachment_code.splice(key, 1)
            },
            addAd() {
                this.attachmentDocumentA1.file_attachment_name.push('')
            },
            removeAd(key) {
                this.attachmentDocumentA1.file_attachment_name.splice(key, 1)
            },
            addKB2() {
                if (this.keywordB2.keyword.length == 0) {
                    this.keywordB2.keyword.push('')
                } else if (this.keywordB2.keyword.length > 0) {
                    if (this.keywordB2.keyword[this.keywordB2.keyword.length-1] == '') {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    } else {
                        this.keywordB2.keyword.push('')
                    }
                }
            },
            removeKB2(key) {
                this.keywordB2.keyword.splice(key, 1)
            },
            addPl() {
                this.plantLevel.plant.push('')
            },
            removePl(key) {
                this.plantLevel.plant.splice(key, 1)
            },
            addPlB2() {
                if (this.plantLevelB2.plant.length == 0) {
                    this.plantLevelB2.plant.push('')
                } else if (this.plantLevelB2.plant.length > 0) {
                    if (this.plantLevelB2.plant[this.plantLevelB2.plant.length-1] != '') {
                        this.plantLevelB2.plant.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlB2(key) {
                this.plantLevelB2.plant.splice(key, 1)
            },
            addPlB3() {
                if (this.plantLevelB3.plant.length == 0) {
                    this.plantLevelB3.plant.push('')
                } else if (this.plantLevelB3.plant.length > 0) {
                    if (this.plantLevelB3.plant[this.plantLevelB3.plant.length-1] != '') {
                        this.plantLevelB3.plant.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlB3(key) {
                this.plantLevelB3.plant.splice(key, 1)
            },
            changeStandardItemNameB2() {                
                let context = this;               
                Api(context, characteristicItemName.get({search: context.inputDraftListB2.item_name})).onSuccess(function(response) {    
                    context.characteristicItemName = response.data.data;
                    context.inputDraftListB2.characteristics.value = [];
                    console.log('inidia')
                    console.log(response.data.data)
                    response.data.data.forEach(function(cin, i) {                           
                        cin.getValue = function(queryString, cb) {                                         
                            Api(context, characteristicItemName.get_characteristic_value(cin.cin_code, {search_char_value: queryString})).onSuccess(function(response) {                                            
                                var values = [];                                
                                response.data.data.data.data.forEach(function(value, j) {
                                    values[j] = {
                                        'code': value['characteristic_value'],
                                        'value': value['characteristic_value']
                                    }
                                });                                                                               
                                cb(values);
                            }).call();       
                        }     
                        context.$set(characteristicItemName,i, cin);
                        /*context.$set(characteristicItemName[i],'getValue', function(queryString, cb) {                                         
                            Api(context, characteristicItemName.get_characteristic_value(cin.cin_code)).onSuccess(function(response) {                                            
                                var values = [];                                
                                response.data.data.data.forEach(function(value, j) {
                                    values[j] = {
                                        'code': value['characteristic_value'],
                                        'value': value['characteristic_value']
                                    }
                                });                                                   
                                cb(values);
                            }).call();       
                        });*/
                    });
                    // for (var i=0; i<response.data.data.length; i++) {
                    //     let context2 = this;               
                    //     context.characteristicValue[i] = [];
                        // Api(context2, characteristicItemName.get_characteristic_value(response.data.data[i].cin_code)).onSuccess(function(response2) {    
                        //     // console.log(response2.data.data.data.data)

                        //     for (var j=0; j<response2.data.data.data.data.length; j++) {
                        //         context.characteristicValue[i][j] = [];
                        //         context.characteristicValue[i][j] = response2.data.data.data.data[j];
                        //     }
                        // }).onError(function(error) {                    
                        //     // if (error.response.status == 404) {
                        //     //     context2.characteristicItemName = [];
                        //     // }
                        // }).call()

                    // }
                    // console.log(response.data.data)
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.characteristicItemName = [];
                    }
                }).call()                
            },
            changeStandardItemNameB3() {                
                let context = this;               
                Api(context, characteristicItemName.get({search: context.inputDraftListB3.item_name})).onSuccess(function(response) {    
                    context.characteristicItemName = response.data.data;
                    context.inputDraftListB3.characteristics.value = [];
                    response.data.data.forEach(function(cin, i) {       
                        context.inputDraftListB3.characteristics.cinv_code.push(cin.cin_code)
                        context.inputDraftListB3.characteristics.checkbox.push(false)
                        cin.getValue = function(queryString, cb) {                                         
                            Api(context, characteristicItemName.get_characteristic_value(cin.cin_code, {search_char_value: queryString})).onSuccess(function(response) {                                            
                                var values = [];                                
                                response.data.data.data.data.forEach(function(value, j) {
                                    values[j] = {
                                        'code': value['characteristic_value'],
                                        'value': value['characteristic_value']
                                    }
                                });                                                                               
                                cb(values);
                            }).call();       
                        }     
                        context.$set(characteristicItemName,i, cin);
                    });
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.characteristicItemName = [];
                    }
                }).call()                
            },
            querySearch(cin_code, cb) {
                let context = this;               
                Api(context, characteristicItemName.get_characteristic_value(cin_code)).onSuccess(function(response) {                                            
                    var values = [];                      
                    response.data.data.data.forEach(function(data, index) {                        
                    });                           
                    cb(values);
                }).call()                                
            },
            createFilter(queryString) {
                return (link) => {
                  return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            addMrpB2() {
                if (this.mrpB2.abc_indicator.length == 0) {
                    this.mrpB2.abc_indicator.push('')
                    this.mrpB2.mrp_type.push('')
                    this.mrpB2.mrp_controller.push('')
                    this.mrpB2.lot_size.push('')
                    this.mrpB2.min.push('')
                    this.mrpB2.max.push('')
                    this.mrpB2.procurement_type.push('')
                    this.mrpB2.special_proc.push('')
                } else if (this.mrpB2.abc_indicator.length > 0) {
                    if (this.mrpB2.abc_indicator[this.mrpB2.abc_indicator.length-1] != '' &&
                        this.mrpB2.mrp_type[this.mrpB2.mrp_type.length-1] != '' &&
                        this.mrpB2.mrp_controller[this.mrpB2.mrp_controller.length-1] != '' &&
                        this.mrpB2.lot_size[this.mrpB2.lot_size.length-1] != '' &&
                        this.mrpB2.min[this.mrpB2.min.length-1] != '' &&
                        this.mrpB2.max[this.mrpB2.max.length-1] != '' &&
                        this.mrpB2.procurement_type[this.mrpB2.procurement_type.length-1] != '' &&
                        this.mrpB2.special_proc[this.mrpB2.special_proc.length-1] != '') {
                        
                        this.mrpB2.abc_indicator.push('')
                        this.mrpB2.mrp_type.push('')
                        this.mrpB2.mrp_controller.push('')
                        this.mrpB2.lot_size.push('')
                        this.mrpB2.min.push('')
                        this.mrpB2.max.push('')
                        this.mrpB2.procurement_type.push('')
                        this.mrpB2.special_proc.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMrpB2(key) {
                this.mrpB2.abc_indicator.splice(key, 1)
                this.mrpB2.mrp_type.splice(key, 1)
                this.mrpB2.mrp_controller.splice(key, 1)
                this.mrpB2.lot_size.splice(key, 1)
                this.mrpB2.min.splice(key, 1)
                this.mrpB2.max.splice(key, 1)
                this.mrpB2.procurement_type.splice(key, 1)
                this.mrpB2.special_proc.splice(key, 1)
            },
            addMrpB3() {
                if (this.mrpB3.abc_indicator.length == 0) {
                    this.mrpB3.abc_indicator.push('')
                    this.mrpB3.mrp_type.push('')
                    this.mrpB3.mrp_controller.push('')
                    this.mrpB3.lot_size.push('')
                    this.mrpB3.min.push('')
                    this.mrpB3.max.push('')
                    this.mrpB3.procurement_type.push('')
                    this.mrpB3.special_proc.push('')
                } else if (this.mrpB3.abc_indicator.length > 0) {
                    if (this.mrpB3.abc_indicator[this.mrpB3.abc_indicator.length-1] != '' &&
                        this.mrpB3.mrp_type[this.mrpB3.mrp_type.length-1] != '' &&
                        this.mrpB3.mrp_controller[this.mrpB3.mrp_controller.length-1] != '' &&
                        this.mrpB3.lot_size[this.mrpB3.lot_size.length-1] != '' &&
                        this.mrpB3.min[this.mrpB3.min.length-1] != '' &&
                        this.mrpB3.max[this.mrpB3.max.length-1] != '' &&
                        this.mrpB3.procurement_type[this.mrpB3.procurement_type.length-1] != '' &&
                        this.mrpB3.special_proc[this.mrpB3.special_proc.length-1] != '') {
                        
                        this.mrpB3.abc_indicator.push('')
                        this.mrpB3.mrp_type.push('')
                        this.mrpB3.mrp_controller.push('')
                        this.mrpB3.lot_size.push('')
                        this.mrpB3.min.push('')
                        this.mrpB3.max.push('')
                        this.mrpB3.procurement_type.push('')
                        this.mrpB3.special_proc.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMrpB3(key) {
                this.mrpB3.abc_indicator.splice(key, 1)
                this.mrpB3.mrp_type.splice(key, 1)
                this.mrpB3.mrp_controller.splice(key, 1)
                this.mrpB3.lot_size.splice(key, 1)
                this.mrpB3.min.splice(key, 1)
                this.mrpB3.max.splice(key, 1)
                this.mrpB3.procurement_type.splice(key, 1)
                this.mrpB3.special_proc.splice(key, 1)
            },
            addAB2() {
                if (this.accountingB2.valuation_class.length == 0) {
                    this.accountingB2.valuation_class.push('')
                    this.accountingB2.valuation_category.push('')
                    this.accountingB2.valuation_type.push('')
                    this.accountingB2.price_control.push('')
                    this.accountingB2.price_determination.push('')
                    this.accountingB2.price_unit.push('')
                } else if (this.accountingB2.valuation_class.length > 0) {
                    if (this.accountingB2.valuation_class[this.accountingB2.valuation_class.length-1] != '' &&
                        this.accountingB2.valuation_category[this.accountingB2.valuation_category.length-1] != '' &&
                        this.accountingB2.valuation_type[this.accountingB2.valuation_type.length-1] != '' &&
                        this.accountingB2.price_control[this.accountingB2.price_control.length-1] != '' &&
                        this.accountingB2.price_determination[this.accountingB2.price_determination.length-1] != '' &&
                        this.accountingB2.price_unit[this.accountingB2.price_unit.length-1] != '') {
                        
                        this.accountingB2.valuation_class.push('')
                        this.accountingB2.valuation_category.push('')
                        this.accountingB2.valuation_type.push('')
                        this.accountingB2.price_control.push('')
                        this.accountingB2.price_determination.push('')
                        this.accountingB2.price_unit.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeAB2(key) {
                this.accountingB2.valuation_class.splice(key, 1)
                this.accountingB2.valuation_category.splice(key, 1)
                this.accountingB2.valuation_type.splice(key, 1)
                this.accountingB2.price_control.splice(key, 1)
                this.accountingB2.price_determination.splice(key, 1)
                this.accountingB2.price_unit.splice(key, 1)
            },
            addAB3() {
                if (this.accountingB3.valuation_class.length == 0) {
                    this.accountingB3.valuation_class.push('')
                    this.accountingB3.valuation_category.push('')
                    this.accountingB3.valuation_type.push('')
                    this.accountingB3.price_control.push('')
                    this.accountingB3.price_determination.push('')
                    this.accountingB3.price_unit.push('')
                } else if (this.accountingB3.valuation_class.length > 0) {
                    if (this.accountingB3.valuation_class[this.accountingB3.valuation_class.length-1] != '' &&
                        this.accountingB3.valuation_category[this.accountingB3.valuation_category.length-1] != '' &&
                        this.accountingB3.valuation_type[this.accountingB3.valuation_type.length-1] != '' &&
                        this.accountingB3.price_control[this.accountingB3.price_control.length-1] != '' &&
                        this.accountingB3.price_determination[this.accountingB3.price_determination.length-1] != '' &&
                        this.accountingB3.price_unit[this.accountingB3.price_unit.length-1] != '') {
                        
                        this.accountingB3.valuation_class.push('')
                        this.accountingB3.valuation_category.push('')
                        this.accountingB3.valuation_type.push('')
                        this.accountingB3.price_control.push('')
                        this.accountingB3.price_determination.push('')
                        this.accountingB3.price_unit.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeAB(key) {
                this.accountingB3.valuation_class.splice(key, 1)
                this.accountingB3.valuation_category.splice(key, 1)
                this.accountingB3.valuation_type.splice(key, 1)
                this.accountingB3.price_control.splice(key, 1)
                this.accountingB3.price_determination.splice(key, 1)
                this.accountingB3.price_unit.splice(key, 1)
            },

            addS() {
                if (this.tempSales.sales_org != '' && this.tempSales.distr_channel != '' && this.tempSales.division != '' && this.tempSales.tax_cat != '' && this.tempSales.tax_class != '' && this.tempSales.gen_item_cat_grp != '' && this.tempSales.account_assign_grp != '' && this.tempSales.item_cat_grp != '' && this.tempSales.trans_group != '' && this.tempSales.loading_group != '' && this.tempSales.profit_center) {
                    this.sales.sales_org.push(this.tempSales.sales_org)
                    this.sales.distr_channel.push(this.tempSales.distr_channel)
                    this.sales.division.push(this.tempSales.division)
                    this.sales.tax_cat.push(this.tempSales.tax_cat)
                    this.sales.tax_class.push(this.tempSales.tax_class)
                    this.sales.gen_item_cat_grp.push(this.tempSales.gen_item_cat_grp)
                    this.sales.account_assign_grp.push(this.tempSales.account_assign_grp)
                    this.sales.item_cat_grp.push(this.tempSales.item_cat_grp)
                    this.sales.trans_group.push(this.tempSales.trans_group)
                    this.sales.loading_group.push(this.tempSales.loading_group)
                    this.sales.profit_center.push(this.tempSales.profit_center)

                    this.tempSales = {
                        sales_org: '',
                        distr_channel: '',
                        division: '',
                        tax_cat: '',
                        tax_class: '',
                        gen_item_cat_grp: '',
                        account_assign_grp: '',
                        item_cat_grp: '',
                        trans_group: '',
                        loading_group: '',
                        profit_center: '',
                    }
                } else {                    
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeS(key) {
                this.sales.sales_org.splice(key, 1)
                this.sales.distr_channel.splice(key, 1)
                this.sales.division.splice(key, 1)
                this.sales.tax_cat.splice(key, 1)
                this.sales.tax_class.splice(key, 1)
                this.sales.gen_item_cat_grp.splice(key, 1)
                this.sales.account_assign_grp.splice(key, 1)
                this.sales.item_cat_grp.splice(key, 1)
                this.sales.trans_group.splice(key, 1)
                this.sales.loading_group.splice(key, 1)
                this.sales.profit_center.splice(key, 1)
            },
            addSB2() {
                if (this.salesB2.sales_org.length == 0) {
                    this.salesB2.sales_org.push('')
                    this.salesB2.distr_channel.push('')
                    this.salesB2.division.push('')
                    this.salesB2.tax_cat.push('')
                    this.salesB2.tax_class.push('')
                    this.salesB2.gen_item_cat_grp.push('')
                    this.salesB2.account_assign_grp.push('')
                    this.salesB2.item_cat_grp.push('')
                    this.salesB2.trans_group.push('')
                    this.salesB2.loading_group.push('')
                    this.salesB2.profit_center.push('')
                } else if (this.salesB2.sales_org.length > 0) {
                    if (this.salesB2.sales_org[this.salesB2.sales_org.length-1] != '' &&
                        this.salesB2.distr_channel[this.salesB2.distr_channel.length-1] != '' &&
                        this.salesB2.division[this.salesB2.division.length-1] != '' &&
                        this.salesB2.tax_cat[this.salesB2.tax_cat.length-1] != '' &&
                        this.salesB2.tax_class[this.salesB2.tax_class.length-1] != '' &&
                        this.salesB2.gen_item_cat_grp[this.salesB2.gen_item_cat_grp.length-1] != '' &&
                        this.salesB2.account_assign_grp[this.salesB2.account_assign_grp.length-1] != '' &&
                        this.salesB2.item_cat_grp[this.salesB2.item_cat_grp.length-1] != '' &&
                        this.salesB2.trans_group[this.salesB2.trans_group.length-1] != '' &&
                        this.salesB2.loading_group[this.salesB2.loading_group.length-1] != '' &&
                        this.salesB2.profit_center[this.salesB2.trans_group.length-1] != '') {
                        
                        this.salesB2.sales_org.push('')
                        this.salesB2.distr_channel.push('')
                        this.salesB2.division.push('')
                        this.salesB2.tax_cat.push('')
                        this.salesB2.tax_class.push('')
                        this.salesB2.gen_item_cat_grp.push('')
                        this.salesB2.account_assign_grp.push('')
                        this.salesB2.item_cat_grp.push('')
                        this.salesB2.trans_group.push('')
                        this.salesB2.loading_group.push('')
                        this.salesB2.profit_center.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }                
            },
            removeSB2(key) {
                this.salesB2.sales_org.splice(key, 1)
                this.salesB2.distr_channel.splice(key, 1)
                this.salesB2.division.splice(key, 1)
                this.salesB2.tax_cat.splice(key, 1)
                this.salesB2.tax_class.splice(key, 1)
                this.salesB2.gen_item_cat_grp.splice(key, 1)
                this.salesB2.account_assign_grp.splice(key, 1)
                this.salesB2.item_cat_grp.splice(key, 1)
                this.salesB2.trans_group.splice(key, 1)
                this.salesB2.loading_group.splice(key, 1)
                this.salesB2.profit_center.splice(key, 1)
            },
            addSB3() {
                if (this.salesB3.sales_org.length == 0) {
                    this.salesB3.sales_org.push('')
                    this.salesB3.distr_channel.push('')
                    this.salesB3.division.push('')
                    this.salesB3.tax_cat.push('')
                    this.salesB3.tax_class.push('')
                    this.salesB3.gen_item_cat_grp.push('')
                    this.salesB3.account_assign_grp.push('')
                    this.salesB3.item_cat_grp.push('')
                    this.salesB3.trans_group.push('')
                    this.salesB3.loading_group.push('')
                    this.salesB3.profit_center.push('')
                } else if (this.salesB3.sales_org.length > 0) {
                    if (this.salesB3.sales_org[this.salesB3.sales_org.length-1] != '' &&
                        this.salesB3.distr_channel[this.salesB3.distr_channel.length-1] != '' &&
                        this.salesB3.division[this.salesB3.division.length-1] != '' &&
                        this.salesB3.tax_cat[this.salesB3.tax_cat.length-1] != '' &&
                        this.salesB3.tax_class[this.salesB3.tax_class.length-1] != '' &&
                        this.salesB3.gen_item_cat_grp[this.salesB3.gen_item_cat_grp.length-1] != '' &&
                        this.salesB3.account_assign_grp[this.salesB3.account_assign_grp.length-1] != '' &&
                        this.salesB3.item_cat_grp[this.salesB3.item_cat_grp.length-1] != '' &&
                        this.salesB3.trans_group[this.salesB3.trans_group.length-1] != '' &&
                        this.salesB3.loading_group[this.salesB3.loading_group.length-1] != '' &&
                        this.salesB3.profit_center[this.salesB3.trans_group.length-1] != '') {
                        
                        this.salesB3.sales_org.push('')
                        this.salesB3.distr_channel.push('')
                        this.salesB3.division.push('')
                        this.salesB3.tax_cat.push('')
                        this.salesB3.tax_class.push('')
                        this.salesB3.gen_item_cat_grp.push('')
                        this.salesB3.account_assign_grp.push('')
                        this.salesB3.item_cat_grp.push('')
                        this.salesB3.trans_group.push('')
                        this.salesB3.loading_group.push('')
                        this.salesB3.profit_center.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }                
            },
            removeSB3(key) {
                this.salesB3.sales_org.splice(key, 1)
                this.salesB3.distr_channel.splice(key, 1)
                this.salesB3.division.splice(key, 1)
                this.salesB3.tax_cat.splice(key, 1)
                this.salesB3.tax_class.splice(key, 1)
                this.salesB3.gen_item_cat_grp.splice(key, 1)
                this.salesB3.account_assign_grp.splice(key, 1)
                this.salesB3.item_cat_grp.splice(key, 1)
                this.salesB3.trans_group.splice(key, 1)
                this.salesB3.loading_group.splice(key, 1)
                this.salesB3.profit_center.splice(key, 1)
            },
            addM() {                
                if (this.tempMrp.abc_indicator != '' && 
                    this.tempMrp.mrp_type != '' && 
                    this.tempMrp.mrp_controller != '' && 
                    this.tempMrp.lot_size != '' && 
                    this.tempMrp.min != '' && 
                    this.tempMrp.max != '' && 
                    this.tempMrp.procurement_type != '' && 
                    this.tempMrp.special_proc) {

                    this.mrp.abc_indicator.push(this.tempMrp.abc_indicator)
                    this.mrp.mrp_type.push(this.tempMrp.mrp_type)
                    this.mrp.mrp_controller.push(this.tempMrp.mrp_controller)
                    this.mrp.lot_size.push(this.tempMrp.lot_size)
                    this.mrp.min.push(this.tempMrp.min)
                    this.mrp.max.push(this.tempMrp.max)
                    this.mrp.procurement_type.push(this.tempMrp.procurement_type)
                    this.mrp.special_proc.push(this.tempMrp.special_proc)

                    this.tempMrp = {
                        abc_indicator: '',
                        mrp_type: '',
                        mrp_controller: '',
                        lot_size: '',
                        min: '',
                        max: '',
                        procurement_type: '',
                        special_proc: '',
                    }
                } else {
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeM(key) {
                this.mrp.abc_indicator.splice(key, 1)
                this.mrp.mrp_type.splice(key, 1)
                this.mrp.mrp_controller.splice(key, 1)
                this.mrp.lot_size.splice(key, 1)
                this.mrp.min.splice(key, 1)
                this.mrp.max.splice(key, 1)
                this.mrp.procurement_type.splice(key, 1)
                this.mrp.special_proc.splice(key, 1)
            },
            addA() {                
                if (this.tempAccounting.valuation_class != '' && 
                    this.tempAccounting.valuation_category != '' && 
                    this.tempAccounting.valuation_type != '' && 
                    this.tempAccounting.price_determination != '' && 
                    this.tempAccounting.price_unit) {

                    this.accounting.valuation_class.push(this.tempAccounting.valuation_class)
                    this.accounting.valuation_category.push(this.tempAccounting.valuation_category)
                    this.accounting.valuation_type.push(this.tempAccounting.valuation_type)
                    this.accounting.price_determination.push(this.tempAccounting.price_determination)
                    this.accounting.price_unit.push(this.tempAccounting.price_unit)

                    // this.tempAccounting = {
                    //     valuation_class: '',
                    //     valuation_category: '',
                    //     valuation_type: '',
                    //     price_determination: '',
                    // }
                } else {
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeA(key) {
                this.accounting.valuation_class.splice(key, 1)
                this.accounting.valuation_category.splice(key, 1)
                this.accounting.valuation_type.splice(key, 1)
                this.accounting.price_determination.splice(key, 1)
                this.accounting.price_unit.splice(key, 1)
            },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },
            changeFileAttachmentNameA1(files) {
                this.attachmentDocumentA1.file_attachment_name[this.attachmentDocumentA1.file_attachment_name.length-1] = files[0]                
            },
            saveA1(save) {
                let context = this; 
                let formData = new FormData(); 

                if (context.inputDraftListA1.mtype_code != '' && 
                    context.inputDraftListA1.item_type_code != '' &&
                    context.inputDraftListA1.plant_code != '' &&
                    context.inputDraftListA1.fab_non_fab != '' &&
                    context.inputDraftListA1.uom != '' &&
                    context.inputDraftListA1.bom_non_bom != '' &&
                    context.inputDraftListA1.item_name != '' &&
                    context.inputDraftListA1.specification != '' &&
                    (context.attachmentDocumentA1.file_attachment_name.length > 0 &&
                    context.attachmentDocumentA1.file_attachment_name[context.attachmentDocumentA1.file_attachment_name.length-1] != '') &&
                    context.inputDraftListA1.note != '') {

                    formData.append('mcr_code', context.mcr_code)
                    formData.append('mtype_code', context.inputDraftListA1.mtype_code)
                    formData.append('item_type_code', context.inputDraftListA1.item_type_code)
                    formData.append('plant_code', context.inputDraftListA1.plant_code)
                    formData.append('fab_non_fab', context.inputDraftListA1.fab_non_fab)
                    formData.append('uom_code', context.inputDraftListA1.uom)
                    formData.append('bom_non_bom', context.inputDraftListA1.bom_non_bom)
                    formData.append('item_name', context.inputDraftListA1.item_name)
                    formData.append('specification', context.inputDraftListA1.specification)
                    formData.append('note', context.inputDraftListA1.note)

                    for (var i=0; i<context.manufacturePartNumberA1.manufacture_code.length; i++) {
                        if (context.manufacturePartNumberA1.manufacture_code[i] != '' &&
                            context.manufacturePartNumberA1.mpn[i] != '' &&
                            context.manufacturePartNumberA1.manufacture_type[i] != '' &&
                            context.manufacturePartNumberA1.manufacture_note[i] != '') {
                            
                            formData.append('manufacture_code['+i+']', context.manufacturePartNumberA1.manufacture_code[i])
                            formData.append('mpn['+i+']', context.manufacturePartNumberA1.mpn[i])
                            formData.append('manufacture_type['+i+']', context.manufacturePartNumberA1.manufacture_type[i])
                            formData.append('manufacture_note['+i+']', context.manufacturePartNumberA1.manufacture_note[i])
                        } else {
                            context.$notify({
                                message: this.tt('please_complete_data', {'title': this.tt('mpn')}),               
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    for (var i=0; i<context.equipmentQuantityInstallA1.equipment_code.length; i++) {
                        if (context.equipmentQuantityInstallA1.equipment_code[i] != '' &&
                            context.equipmentQuantityInstallA1.qty_installed[i] != '' &&
                            context.equipmentQuantityInstallA1.drawing_number[i] != '') {
                            
                            formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA1.equipment_code[i])
                            formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA1.qty_installed[i])
                            formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA1.drawing_number[i])
                        } else {
                            context.$notify({
                                message: this.tt('please_complete_data', {'title': this.tt('equipment_&_quantity_install')}),                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    let api = '';
                    if (save == 'add') {
                        for (var i=0; i<context.attachmentDocumentA1.file_attachment_name.length; i++) {
                            formData.append('file_attachment_name['+i+']', context.attachmentDocumentA1.file_attachment_name[i])
                        }
                        api = Api(context, draftList.createNewMcrItem(formData));
                    } else {
                        let index = 0;
                        for (var i=0; i<context.attachmentDocumentA1.file_attachment_name.length; i++) {
                            if (context.attachmentDocumentA1.file_attachment_name[i].lastModified != null) {
                                formData.append('file_attachment_name['+index+']', context.attachmentDocumentA1.file_attachment_name[i])
                                index++;
                            }                            
                        }
                        api = Api(context, draftList.updateMcrItem(formData, context.inputDraftListA1.id));
                    }
                    context.form.a1.btnSave.onLoading = true;
                    api.onSuccess(function(response) {    
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.a1.show = false;
                    }).onFinish(function() {
                        context.form.a1.btnSave.onLoading = false;
                    }) 
                    .call()
                } else {
                    context.$notify({
                        message: this.tt('please_complete_data', {'title': this.tt('basic_data_&_specification')}),                 
                        type: 'danger'
                    });
                }
            },
            changeMaterialNumberA2() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA2.material_number})).onSuccess(function(response) {    
                    // context.endUserSearchMaterial = response.data.data.data.data;                    
                    context.inputDraftListA2.item_type_code = response.data.data.part_master[0].item_type_code;
                    context.inputDraftListA2.bom_non_bom = response.data.data.part_master[0].bom_non_bom;
                    context.inputDraftListA2.fab_non_fab = response.data.data.part_master[0].fab_non_fab;
                    context.inputDraftListA2.item_name = response.data.data.part_master[0].item_name;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.materialGroup = [];
                    }
                })
                .call()
            },
            changeMaterialNumberA4() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA4.material_number})).onSuccess(function(response) {    
                    for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                        // alert(response.data.data.part_manufacture_ref[i].material_number_mpn)
                        context.manufacturePartNumberA4.mpn[i] = response.data.data.part_manufacture_ref[i].material_number_mpn;
                    }
                    for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                        context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                    }                    
                    // console.log(response.data.data.part_manufacture_ref)
                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.materialGroup = [];
                    }
                })
                .call()
            },
            changeClientPlantLevelA2() {
                // if (this.inputDraftListA2.client_plant_level == 'client') {
                //     this.condition.a2.client_level.show = true;
                // } else {
                //     this.condition.a2.client_level.show = false;
                // }
            },
            saveA2(save) {
                let context = this;    
                let formData = new FormData();

                if (context.inputDraftListA2.material_number != '' &&
                    context.inputDraftListA2.client_plant_level != '' &&
                    context.inputDraftListA2.reason != '') {        
                    let data = {
                        mcr_code: context.mcr_code,
                        material_number: context.inputDraftListA2.material_number,
                        client_plant_level: context.inputDraftListA2.client_plant_level,
                        // item_type_code: '',
                        // critical_non_critical: context.inputDraftListA2.critical_non_critical,
                        // bom_non_bom: '',
                        // fab_non_fab: '',
                        // item_name: '',
                        // specification: '',
                        // manufacture_code: [],
                        // mpn: [],
                        // manufacture_type: [],
                        // manufacture_note: [],
                        // equipment_code: [],
                        // qty_installed: [],
                        // drawing_number: [],
                        // abc_indicator: '',
                        // min_stock: context.inputDraftListA2.min_stock,
                        // mrp_types: '',
                        // max_stock: context.inputDraftListA2.min_stock,
                        reason: context.inputDraftListA2.reason,
                        note: context.inputDraftListA2.note,
                    }

                    // formData.append('mcr_code', context.mcr_code)
                    // formData.append('material_number', context.inputDraftListA2.material_number)
                    // formData.append('client_plant_level', context.inputDraftListA2.client_plant_level)

                    if (context.inputDraftListA2.client_plant_level == 'client') {
                        if (context.inputDraftListA2.critical_non_critical != '') {
                            data.critical_non_critical = context.inputDraftListA2.critical_non_critical;
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                        data.item_type_code = context.inputDraftListA2.item_type_code;
                        data.bom_non_bom = context.inputDraftListA2.bom_non_bom;
                        data.fab_non_fab = context.inputDraftListA2.fab_non_fab;
                        data.item_name = context.inputDraftListA2.item_name;
                        data.specification = context.inputDraftListA2.specification;
                        
                        // formData.append('item_type_code', context.inputDraftListA2.item_type_code)
                        // formData.append('bom_non_bom', context.inputDraftListA2.bom_non_bom)
                        // formData.append('fab_non_fab', context.inputDraftListA2.fab_non_fab)
                        // formData.append('item_name', context.inputDraftListA2.item_name)
                        // formData.append('specification', context.inputDraftListA2.specification)

                        for (var i=0; i<context.manufacturePartNumberA2.manufacture_code.length; i++) {
                            if (context.manufacturePartNumberA2.manufacture_code[i] != '' &&
                                context.manufacturePartNumberA2.mpn[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_type[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_note[i] != '') {
                                

                                data.manufacture_code = context.manufacturePartNumberA2.manufacture_code;
                                data.mpn = context.manufacturePartNumberA2.mpn;
                                data.manufacture_type = context.manufacturePartNumberA2.manufacture_type;
                                data.manufacture_note = context.manufacturePartNumberA2.manufacture_note;

                                // formData.append('manufacture_code['+i+']', context.manufacturePartNumberA2.manufacture_code[i])
                                // formData.append('mpn['+i+']', context.manufacturePartNumberA2.mpn[i])
                                // formData.append('manufacture_type['+i+']', context.manufacturePartNumberA2.manufacture_type[i])
                                // formData.append('manufacture_note['+i+']', context.manufacturePartNumberA2.manufacture_note[i])
                            } else {
                                context.$notify({
                                    message: 'Please complete data!',                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }
                        
                        for (var i=0; i<context.equipmentQuantityInstallA2.plant.length; i++) {
                            if (context.equipmentQuantityInstallA2.plant[i] != '' &&
                                context.equipmentQuantityInstallA2.equipment_code[i] != '' &&
                                context.equipmentQuantityInstallA2.qty_installed[i] != '' &&
                                context.equipmentQuantityInstallA2.drawing_number[i] != '') {
                                
                                data.plant_equipment_code = context.equipmentQuantityInstallA2.plant;
                                data.equipment_code = context.equipmentQuantityInstallA2.equipment_code;
                                data.qty_installed = context.equipmentQuantityInstallA2.qty_installed;
                                data.drawing_number = context.equipmentQuantityInstallA2.drawing_number;

                                // formData.append('plant['+i+']', context.equipmentQuantityInstallA2.plant[i])
                                // formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA2.equipment_code[i])
                                // formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA2.qty_installed[i])
                                // formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA2.drawing_number[i])
                            } else {
                                context.$notify({
                                    message: 'Please complete data!',                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }

                        // console.log(data)
                        // return false
                    } else {
                        if (context.inputDraftListA2.min_stock != '' &&
                            context.inputDraftListA2.max_stock != '') {
                                // return false
                                data.plant_code = context.inputDraftListA2.plant_code;
                                data.abc_indicator = context.inputDraftListA2.abc_indicator;
                                data.min_stock = context.inputDraftListA2.min_stock;
                                data.mrp_types = context.inputDraftListA2.mrp_types;
                                data.max_stock = context.inputDraftListA2.max_stock;

                                // formData.append('abc_indicator_code', context.inputDraftListA2.abc_indicator_code)
                                // formData.append('min_stock', context.inputDraftListA2.min_stock)
                                // formData.append('mrp_type_code', context.inputDraftListA2.mrp_type_code)
                                // formData.append('max_stock', context.inputDraftListA2.max_stock)
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false
                        }
                    }

                    // formData.append('reason', context.inputDraftListA2.reason)
                    // formData.append('note', context.inputDraftListA2.note)

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA2.id));
                    }
                    api.onSuccess(function(response) {    
                        context.draftList = response.data.data.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    }).call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            saveA3(save) {
                let context = this;               
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListA3.material_number,
                    plant_code: context.inputDraftListA3.plant_code,
                    location_code: context.inputDraftListA3.location_code,
                    valuation_type_code: context.inputDraftListA3.valuation_type_code,
                    note: '',
                }

                if (context.inputDraftListA3.material_number != '' &&
                    context.inputDraftListA3.plant_code != '' &&
                    context.inputDraftListA3.location_code != '' &&
                    context.inputDraftListA3.valuation_type_code != '') {

                    for (var i=0; i<context.manufacturePartNumberA3.manufacture_code.length; i++) {
                        if (context.manufacturePartNumberA3.manufacture_code[i] != '' &&
                            context.manufacturePartNumberA3.mpn[i] != '' &&
                            context.manufacturePartNumberA3.manufacture_type[i] != '' &&
                            context.manufacturePartNumberA3.manufacture_note[i] != '') {
                            

                            data.manufacture_code = context.manufacturePartNumberA3.manufacture_code;
                            data.mpn = context.manufacturePartNumberA3.mpn;
                            data.manufacture_type = context.manufacturePartNumberA3.manufacture_type;
                            data.manufacture_note = context.manufacturePartNumberA3.manufacture_note;

                            // formData.append('manufacture_code['+i+']', context.manufacturePartNumberA3.manufacture_code[i])
                            // formData.append('mpn['+i+']', context.manufacturePartNumberA3.mpn[i])
                            // formData.append('manufacture_type['+i+']', context.manufacturePartNumberA3.manufacture_type[i])
                            // formData.append('manufacture_note['+i+']', context.manufacturePartNumberA3.manufacture_note[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                    
                    for (var i=0; i<context.equipmentQuantityInstallA3.plant.length; i++) {
                        if (context.equipmentQuantityInstallA3.plant[i] != '' &&
                            context.equipmentQuantityInstallA3.equipment_code[i] != '' &&
                            context.equipmentQuantityInstallA3.qty_installed[i] != '' &&
                            context.equipmentQuantityInstallA3.drawing_number[i] != '') {
                            
                            data.plant_equipment_code = context.equipmentQuantityInstallA3.plant;
                            data.equipment_code = context.equipmentQuantityInstallA3.equipment_code;
                            data.qty_installed = context.equipmentQuantityInstallA3.qty_installed;
                            data.drawing_number = context.equipmentQuantityInstallA3.drawing_number;

                            // formData.append('plant['+i+']', context.equipmentQuantityInstallA3.plant[i])
                            // formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA3.equipment_code[i])
                            // formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA3.qty_installed[i])
                            // formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA3.drawing_number[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    data.note = context.inputDraftListA3.note;
                    
                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA3.id));
                    }
                    api.onSuccess(function(response) {    
                        context.draftList = response.data.data.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            saveA4(save) {
                let context = this;               
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListA4.material_number,
                    plant_code: context.inputDraftListA4.plant_code,
                    location_code: context.inputDraftListA4.location_code,
                    valuation_type_code: '',
                    sales_org_code: '',
                    distr_channel_code: '',
                    warehouse_number: context.inputDraftListA4.warehouse_number,
                    storage_type_code: context.inputDraftListA4.storage_type_code,
                    reason: context.inputDraftListA4.reason,
                    note: '',
                }

                if (context.inputDraftListA4.material_number != '' &&
                    context.inputDraftListA4.plant_code != '' &&
                    context.inputDraftListA4.location_code != '' &&
                    context.inputDraftListA4.warehouse_number != '' &&
                    context.inputDraftListA4.storage_type_code != '' &&
                    context.inputDraftListA4.reason != '') {

                    for (var i=0; i<context.manufacturePartNumberA4.mpn.length; i++) {
                        if (context.manufacturePartNumberA4.mpn[i] != '') {
                            

                            data.mpn = context.manufacturePartNumberA4.mpn;

                            // formData.append('manufacture_code['+i+']', context.manufacturePartNumberA4.manufacture_code[i])
                            // formData.append('mpn['+i+']', context.manufacturePartNumberA4.mpn[i])
                            // formData.append('manufacture_type['+i+']', context.manufacturePartNumberA4.manufacture_type[i])
                            // formData.append('manufacture_note['+i+']', context.manufacturePartNumberA4.manufacture_note[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                    
                    for (var i=0; i<context.equipmentQuantityInstallA4.equipment_code.length; i++) {
                        if (context.equipmentQuantityInstallA4.equipment_code[i] != '') {                    
                            data.equipment_code = context.equipmentQuantityInstallA4.equipment_code;

                            // formData.append('plant['+i+']', context.equipmentQuantityInstallA4.plant[i])
                            // formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA4.equipment_code[i])
                            // formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA4.qty_installed[i])
                            // formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA4.drawing_number[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    data.valuation_type_code = context.inputDraftListA4.valuation_type_code;
                    data.sales_org_code = context.inputDraftListA4.sales_org_code;
                    data.distr_channel_code = context.inputDraftListA4.distr_channel_code;
                    data.note = context.inputDraftListA4.note;

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA4.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        context.draftList = response.data.data.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            saveB1(save) {
                let context = this;
                let data = {
                    mcr_code: context.mcr_code,
                    specification: context.inputDraftListB1.description,
                    parameter: [],
                    category: [],
                    description: [],
                    reason: context.inputDraftListB1.reason,
                    note: ''
                }

                if (context.inputDraftListB1.description != '' &&
                    context.inputDraftListB1.reason != '') {
                    for (var i=0; i<context.paramsB1.parameter.length; i++) {
                        if (context.paramsB1.parameter[i] != '' &&
                            context.paramsB1.category[i] != '' &&
                            context.paramsB1.description[i] != '') {
                            data.parameter[i] = context.paramsB1.parameter[i];
                            data.category[i] = context.paramsB1.category[i];
                            data.description[i] = context.paramsB1.description[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    } 
                    data.note = context.inputDraftListB1.po_text_memo;

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListB1.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        //context.draftList = response.data.data.data.data;
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.b1.show = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }                
            },
            changeFileAttachmentNameB2(files) {
                this.attachmentDocumentB2.file_attachment_code[this.attachmentDocumentB2.file_attachment_code.length-1] = files[0]                
            },
            saveB2(save) {
                let context = this;
                let formData = new FormData();
                let data = {
                    mcr_code: context.mcr_code,
                    item_name: context.inputDraftListB2.item_name,
                    item_type_code: context.inputDraftListB2.item_type_code,
                        group_class: context.inputDraftListB2.group_class,
                    uom_code: context.inputDraftListB2.uom_code,
                    mtype_code: context.inputDraftListB2.mtype_code,
                    catalog_type: context.inputDraftListB2.catalog_type,
                    mgroup_code: context.inputDraftListB2.mgroup_code,
                    fab_non_fab: context.inputDraftListB2.fab_non_fab,
                        scope_of_supply: '',
                    bom_non_bom: context.inputDraftListB2.bom_non_bom,
                    cinv_code: context.inputDraftListB2.characteristics.item_name,
                    view: context.inputDraftListB2.characteristics.view,
                        checkbox: [],
                    manufacture_code: context.manufacturePartNumberB2.manufacture_code,
                    mpn: context.manufacturePartNumberB2.mpn,
                    manufacture_type: context.manufacturePartNumberB2.manufacture_type,
                    manufacture_note: context.manufacturePartNumberB2.manufacture_note,
                    prc_plant_sales: context.plantLevelB2.plant,
                    prc_sales_org_code: context.salesB2.sales_org,
                    prc_distr_channel_code: context.salesB2.distr_channel,
                    prc_division_code: context.salesB2.division,
                    prc_tax_cat_code: context.salesB2.tax_class,
                    prc_tax_class_code: context.salesB2.tax_class,
                    prc_gen_item_cat_grp_code: context.salesB2.gen_item_cat_grp,
                    prc_acc_ass_grp_code: context.salesB2.account_assign_grp,
                    prc_item_cat_grp_code: context.salesB2.item_cat_grp,
                    prc_trans_group_code: context.salesB2.trans_group,
                    prc_loading_group_code: context.salesB2.loading_group,
                    prc_profit_center_code: context.salesB2.profit_center,
                    prc_abc_indicator_code: context.mrpB2.abc_indicator,
                    prc_mrp_type_code: context.mrpB2.mrp_type,
                    prc_mrp_controller_code: context.mrpB2.mrp_controller,
                    prc_lot_size_code: context.mrpB2.lot_size,
                    prc_min_stock: context.mrpB2.min,
                    prc_max_stock: context.mrpB2.max,
                    prc_procurement_type_code: context.mrpB2.procurement_type,
                    prc_special_proc_type_code: context.mrpB2.special_proc,
                    prc_valuation_class_code: context.accountingB2.valuation_class,
                    prc_valuation_category_code: context.accountingB2.valuation_category,
                    prc_valuation_type_code: context.accountingB2.valuation_type,
                    prc_price_control_code: context.accountingB2.price_control,
                    prc_price_determination: context.accountingB2.price_determination,
                    prc_standard_price: context.accountingB2.price_unit,
                    prc_price_unit: context.accountingB2.price_unit,
                    prc_plant_equipment: context.equipmentQuantityInstallB2.plant,
                    prc_equipment_code: context.equipmentQuantityInstallB2.equipment_code,
                    prc_qty_installed: context.equipmentQuantityInstallB2.qty_installed,
                    prc_drawing_number: context.equipmentQuantityInstallB2.drawing_number,
                    file_attachment_code: context.attachmentDocumentB2.file_attachment_code,
                    keyword: context.keywordB2.keyword,
                    note: context.inputDraftListB2.note
                }

                if (context.inputDraftListB2.item_name != '' &&
                    context.inputDraftListB2.item_type_code != '' &&
                    context.inputDraftListB2.group_class != '' &&
                    context.inputDraftListB2.uom_code != '' &&
                    context.inputDraftListB2.mtype_code != '' &&
                    context.inputDraftListB2.catalog_type != '' &&
                    context.inputDraftListB2.mgroup_code != '' &&
                    context.inputDraftListB2.fab_non_fab != '' &&
                    context.inputDraftListB2.scope_of_supply != '' &&
                    context.inputDraftListB2.bom_non_bom != '' &&
                    context.inputDraftListB2.note != '') {

                    formData.append('mcr_code', context.mcr_code)
                    formData.append('item_name', context.inputDraftListB2.item_name)
                    formData.append('item_type_code', context.inputDraftListB2.item_type_code)
                    formData.append('group_class', context.inputDraftListB2.group_class)
                    formData.append('uom_code', context.inputDraftListB2.uom)
                    formData.append('mtype_code', context.inputDraftListB2.mtype_code)
                    formData.append('catalog_type', context.inputDraftListB2.catalog_type)
                    formData.append('mgroup_code', context.inputDraftListB2.material_group)
                    formData.append('fab_non_fab', context.inputDraftListB2.fab_non_fab)
                    formData.append('scope_of_supply', '')
                    formData.append('bom_non_bom', context.inputDraftListB2.bom_non_bom)

                    for (var i=0; i<context.manufacturePartNumberB2.manufacture_code.length; i++) {
                        if (context.manufacturePartNumberB2.manufacture_code[i] != '' &&
                            context.manufacturePartNumberB2.mpn[i] != '' &&
                            context.manufacturePartNumberB2.manufacture_type[i] != '' &&
                            context.manufacturePartNumberB2.manufacture_note[i] != '') {
                            data.manufacture_code[i] = context.manufacturePartNumberB2.manufacture_code[i];
                            data.mpn[i] = context.manufacturePartNumberB2.mpn[i];
                            data.manufacture_type[i] = context.manufacturePartNumberB2.manufacture_type[i];
                            data.manufacture_note[i] = context.manufacturePartNumberB2.manufacture_note[i];

                            formData.append('manufacture_code['+i+']', context.manufacturePartNumberB2.manufacture_code[i])
                            formData.append('mpn['+i+']', context.manufacturePartNumberB2.mpn[i])
                            formData.append('manufacture_type['+i+']', context.manufacturePartNumberB2.manufacture_type[i])
                            formData.append('manufacture_note['+i+']', context.manufacturePartNumberB2.manufacture_note[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.plantLevelB2.plant.length; i++) {
                        if (context.plantLevelB2.plant[i] != '') {
                            data.prc_plant_sales[i] = context.plantLevelB2.plant[i];

                            formData.append('prc_plant_sales['+i+']', context.plantLevelB2.plant[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.salesB2.sales_org.length; i++) {
                        if (context.salesB2.sales_org[i] != '' &&
                            context.salesB2.distr_channel[i] != '' &&
                            context.salesB2.division[i] != '' &&
                            context.salesB2.tax_class[i] != '' &&
                            context.salesB2.tax_class[i] != '' &&
                            context.salesB2.gen_item_cat_grp[i] != '' &&
                            context.salesB2.account_assign_grp[i] != '' &&
                            context.salesB2.item_cat_grp[i] != '' &&
                            context.salesB2.trans_group[i] != '' &&
                            context.salesB2.loading_group[i] != '' &&
                            context.salesB2.profit_center[i] != '') {
                            data.prc_sales_org_code[i] = context.salesB2.sales_org[i];
                            data.prc_distr_channel_code[i] = context.salesB2.distr_channel[i];
                            data.prc_division_code[i] = context.salesB2.division[i];
                            data.prc_tax_cat_code[i] = context.salesB2.tax_class[i];
                            data.prc_tax_class_code[i] = context.salesB2.tax_class[i];
                            data.prc_gen_item_cat_grp_code[i] = context.salesB2.gen_item_cat_grp[i];
                            data.prc_acc_ass_grp_code[i] = context.salesB2.account_assign_grp[i];
                            data.prc_item_cat_grp_code[i] = context.salesB2.item_cat_grp[i];
                            data.prc_trans_group_code[i] = context.salesB2.trans_group[i];
                            data.prc_loading_group_code[i] = context.salesB2.loading_group[i];
                            data.prc_profit_center_code[i] = context.salesB2.profit_center[i];

                            formData.append('prc_sales_org['+i+']', context.salesB2.sales_org[i])
                            formData.append('prc_distr_channel_code['+i+']', context.salesB2.distr_channel[i])
                            formData.append('prc_division_code['+i+']', context.salesB2.division[i])
                            formData.append('prc_tax_cat_code['+i+']', context.salesB2.tax_class[i])
                            formData.append('prc_tax_class_code['+i+']', context.salesB2.tax_class[i])
                            formData.append('prc_gen_item_cat_grp_code['+i+']', context.salesB2.gen_item_cat_grp[i])
                            formData.append('prc_acc_ass_grp_code['+i+']', context.salesB2.account_assign_grp[i])
                            formData.append('prc_trans_group_code['+i+']', context.salesB2.trans_group[i])
                            formData.append('prc_loading_group_code['+i+']', context.salesB2.loading_group[i])
                            formData.append('prc_profit_center_code['+i+']', context.salesB2.profit_center[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.mrpB2.abc_indicator.length; i++) {
                        if (context.mrpB2.abc_indicator[i] != '' &&
                            context.mrpB2.mrp_type[i] != '' &&
                            context.mrpB2.mrp_controller[i] != '' &&
                            context.mrpB2.lot_size[i] != '' &&
                            context.mrpB2.min[i] != '' &&
                            context.mrpB2.max[i] != '' &&
                            context.mrpB2.procurement_type[i] != '' &&
                            context.mrpB2.special_proc[i] != '') {
                            data.prc_abc_indicator_code[i] = context.mrpB2.abc_indicator[i];
                            data.prc_mrp_type_code[i] = context.mrpB2.mrp_type[i];
                            data.prc_mrp_controller_code[i] = context.mrpB2.mrp_controller[i];
                            data.prc_lot_size_code[i] = context.mrpB2.lot_size[i];
                            data.prc_min_stock[i] = context.mrpB2.min[i];
                            data.prc_max_stock[i] = context.mrpB2.max[i];
                            data.prc_procurement_type_code[i] = context.mrpB2.procurement_type[i];
                            data.prc_special_proc_type_code[i] = context.mrpB2.special_proc[i];

                            formData.append('prc_abc_indicator_code['+i+']', context.mrpB2.abc_indicator[i])
                            formData.append('prc_mrp_type_code['+i+']', context.mrpB2.mrp_type[i])
                            formData.append('prc_mrp_controller_code['+i+']', context.mrpB2.mrp_controller[i])
                            formData.append('prc_lot_size_code['+i+']', context.mrpB2.lot_size[i])
                            formData.append('prc_min_stock_code['+i+']', context.mrpB2.min[i])
                            formData.append('prc_max_stock_code['+i+']', context.mrpB2.max[i])
                            formData.append('prc_procurement_type_code['+i+']', context.mrpB2.procurement_type[i])
                            formData.append('prc_special_proc_type_code['+i+']', context.mrpB2.special_proc[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.accountingB2.valuation_class.length; i++) {
                        if (context.accountingB2.valuation_class[i] != '' &&
                            context.accountingB2.valuation_category[i] != '' &&
                            context.accountingB2.valuation_type[i] != '' &&
                            context.accountingB2.price_control[i] != '' &&
                            context.accountingB2.price_determination[i] != '' &&
                            context.accountingB2.price_unit[i] != '') {
                            data.prc_valuation_class_code[i] = context.accountingB2.valuation_class[i];
                            data.prc_valuation_category_code[i] = context.accountingB2.valuation_category[i];
                            data.prc_valuation_type_code[i] = context.accountingB2.valuation_type[i];
                            data.prc_price_control_code[i] = context.accountingB2.price_control[i];
                            data.prc_price_determination[i] = context.accountingB2.price_determination[i];
                            data.prc_standard_price[i] = context.accountingB2.price_unit[i];

                            formData.append('prc_valuation_class_code['+i+']', context.accountingB2.valuation_class[i])
                            formData.append('prc_valuation_category_code['+i+']', context.accountingB2.valuation_category[i])
                            formData.append('prc_valuation_type_code['+i+']', context.accountingB2.valuation_type[i])
                            formData.append('prc_price_control_code['+i+']', context.accountingB2.price_control[i])
                            formData.append('prc_price_determination_code['+i+']', context.accountingB2.price_determination[i])
                            formData.append('prc_standard_price_code['+i+']', context.accountingB2.price_unit[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.equipmentQuantityInstallB2.plant_equipment_code.length; i++) {
                        if (context.equipmentQuantityInstallB2.plant_equipment_code[i] != '' &&
                            context.equipmentQuantityInstallB2.equipment_code[i] != '' &&
                            context.equipmentQuantityInstallB2.qty_installed[i] != '' &&
                            context.equipmentQuantityInstallB2.drawing_number[i] != '') {
                            data.prc_plant_equipment[i] = context.equipmentQuantityInstallB2.plant_equipment_code[i];
                            data.prc_equipment_code[i] = context.equipmentQuantityInstallB2.equipment_code[i];
                            data.prc_qty_installed[i] = context.equipmentQuantityInstallB2.qty_installed[i];
                            data.prc_drawing_number[i] = context.equipmentQuantityInstallB2.drawing_number[i];

                            formData.append('prc_plant_equipment['+i+']', context.equipmentQuantityInstallB2.plant_equipment_code[i])
                            formData.append('prc_equipment_code['+i+']', context.equipmentQuantityInstallB2.equipment_code[i])
                            formData.append('prc_qty_installed['+i+']', context.equipmentQuantityInstallB2.qty_installed[i])
                            formData.append('prc_drawing_number['+i+']', context.equipmentQuantityInstallB2.drawing_number[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.attachmentDocumentB2.file_attachment_code.length; i++) {
                        if (context.attachmentDocumentB2.file_attachment_code.length > 0 &&
                            context.attachmentDocumentB2.file_attachment_code[context.attachmentDocumentB2.file_attachment_code.length-1] != '') {
                            data.file_attachment_code[i] = context.attachmentDocumentB2.file_attachment_code[i];
                            
                            formData.append('file_attachment_code['+i+']', context.attachmentDocumentB2.file_attachment_code[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.keywordB2.keyword.length; i++) {
                        if (context.keywordB2.keyword[i] != '') {
                            data.keyword[i] = context.keywordB2.keyword[i];

                            formData.append('keyword['+i+']', context.keywordB2.keyword[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                    
                    data.note = context.inputDraftListB2.po_text_memo;
                    formData.append('note', context.inputDraftListB2.note)

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItem(formData));
                    } else {
                        api = Api(context, draftList.updateMcrItem(formData, context.inputDraftListB1.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        context.draftList = response.data.data.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }                
            },
            saveB3(save) {
                let context = this;
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListB3.material_number,
                    client_or_plant: context.inputDraftListB3.client_or_plant,
                    item_name: context.inputDraftListB3.item_name,
                    item_type: context.inputDraftListB3.item_type,
                    fab_non_fab: context.inputDraftListB3.fab_non_fab,
                    bom_non_bom: context.inputDraftListB3.bom_non_bom,
                    cinv_code: context.inputDraftListB3.characteristics.cinv_code,
                    // view: context.inputDraftListB3.characteristics.value,
                    view: [],
                    checkbox: context.inputDraftListB3.characteristics.checkbox,
                    po_text_memo: context.inputDraftListB3.po_text_memo,
                    reason: context.inputDraftListB3.reason,
                    note: context.inputDraftListB3.note,
                    manufacture_code: context.manufacturePartNumberB3.manufacture_code,
                    mpn: context.manufacturePartNumberB3.mpn,
                    manufacture_type: context.manufacturePartNumberB3.manufacture_type,
                    manufacture_note: context.manufacturePartNumberB3.manufacture_note,
                    prc_plant_equipment: context.equipmentQuantityInstallB3.plant,
                    prc_equipment_code: context.equipmentQuantityInstallB3.equipment_code,
                    prc_qty_installed: context.equipmentQuantityInstallB3.qty_installed,
                    prc_drawing_number: context.equipmentQuantityInstallB3.drawing_number,
                    prc_plant_sales: context.plantLevelB3.plant,
                    prc_sales_org_code: context.salesB3.sales_org,
                    prc_distr_channel_code: context.salesB3.distr_channel,
                    prc_division_code: context.salesB3.division,
                    prc_tax_cat_code: context.salesB3.tax_class,
                    prc_tax_class_code: context.salesB3.tax_class,
                    prc_gen_item_cat_grp_code: context.salesB3.gen_item_cat_grp,
                    prc_acc_ass_grp_code: context.salesB3.account_assign_grp,
                    prc_item_cat_grp_code: context.salesB3.item_cat_grp,
                    prc_trans_group_code: context.salesB3.trans_group,
                    prc_loading_group_code: context.salesB3.loading_group,
                    prc_profit_center_code: context.salesB3.profit_center,
                    prc_abc_indicator_code: context.mrpB3.abc_indicator,
                    prc_mrp_type_code: context.mrpB3.mrp_type,
                    prc_mrp_controller_code: context.mrpB3.mrp_controller,
                    prc_lot_size_code: context.mrpB3.lot_size,
                    prc_min_stock: context.mrpB3.min,
                    prc_max_stock: context.mrpB3.max,
                    prc_procurement_type_code: context.mrpB3.procurement_type,
                    prc_special_proc_type_code: context.mrpB3.special_proc,
                    prc_valuation_class_code: context.accountingB3.valuation_class,
                    prc_valuation_category_code: context.accountingB3.valuation_category,
                    prc_valuation_type_code: context.accountingB3.valuation_type,
                    prc_price_control_code: context.accountingB3.price_control,
                    prc_price_determination: context.accountingB3.price_determination,
                    prc_standard_price: context.accountingB3.price_unit,
                    prc_price_unit: context.accountingB3.price_unit,
                }

                if (context.inputDraftListB3.material_number != '' &&
                    context.inputDraftListB3.client_or_plant != '' &&
                    context.inputDraftListB3.item_name != '' &&
                    context.inputDraftListB3.item_type != '' &&
                    context.inputDraftListB3.fab_non_fab != '' &&
                    context.inputDraftListB3.bom_non_bom != '' &&
                    context.inputDraftListB3.characteristics.cinv_code != '' &&
                    context.inputDraftListB3.characteristics.value != '' &&
                    context.inputDraftListB3.characteristics.checkbox != '' &&
                    context.inputDraftListB3.po_text_memo != '' &&
                    context.inputDraftListB3.reason != '' &&
                    context.inputDraftListB3.note != '') {
                          
                    context.inputDraftListB3.characteristics.checkbox.forEach(function(v, k) {
                        if (v) {
                            data.view.push('checked')
                        } else {
                            data.view.push(null)
                        }
                    });

                    for (var i=0; i<context.manufacturePartNumberB3.manufacture_code.length; i++) {
                        if (context.manufacturePartNumberB3.manufacture_code[i] != '' &&
                            context.manufacturePartNumberB3.mpn[i] != '' &&
                            context.manufacturePartNumberB3.manufacture_type[i] != '' &&
                            context.manufacturePartNumberB3.manufacture_note[i] != '') {
                            data.manufacture_code[i] = context.manufacturePartNumberB3.manufacture_code[i];
                            data.mpn[i] = context.manufacturePartNumberB3.mpn[i];
                            data.manufacture_type[i] = context.manufacturePartNumberB3.manufacture_type[i];
                            data.manufacture_note[i] = context.manufacturePartNumberB3.manufacture_note[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.equipmentQuantityInstallB3.plant.length; i++) {
                        if (context.equipmentQuantityInstallB3.plant[i] != '' &&
                            context.equipmentQuantityInstallB3.equipment_code[i] != '' &&
                            context.equipmentQuantityInstallB3.qty_installed[i] != '' &&
                            context.equipmentQuantityInstallB3.drawing_number[i] != '') {
                            data.prc_plant_equipment[i] = context.equipmentQuantityInstallB3.plant[i];
                            data.prc_equipment_code[i] = context.equipmentQuantityInstallB3.equipment_code[i];
                            data.prc_qty_installed[i] = context.equipmentQuantityInstallB3.qty_installed[i];
                            data.prc_drawing_number[i] = context.equipmentQuantityInstallB3.drawing_number[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.plantLevelB3.plant.length; i++) {
                        if (context.plantLevelB3.plant[i] != '') {
                            data.prc_plant_sales[i] = context.plantLevelB3.plant[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.salesB3.sales_org.length; i++) {
                        if (context.salesB3.sales_org[i] != '' &&
                            context.salesB3.distr_channel[i] != '' &&
                            context.salesB3.division[i] != '' &&
                            context.salesB3.tax_class[i] != '' &&
                            context.salesB3.tax_class[i] != '' &&
                            context.salesB3.gen_item_cat_grp[i] != '' &&
                            context.salesB3.account_assign_grp[i] != '' &&
                            context.salesB3.item_cat_grp[i] != '' &&
                            context.salesB3.trans_group[i] != '' &&
                            context.salesB3.loading_group[i] != '' &&
                            context.salesB3.profit_center[i] != '') {
                            data.prc_sales_org_code[i] = context.salesB3.sales_org[i];
                            data.prc_distr_channel_code[i] = context.salesB3.distr_channel[i];
                            data.prc_division_code[i] = context.salesB3.division[i];
                            data.prc_tax_cat_code[i] = context.salesB3.tax_class[i];
                            data.prc_tax_class_code[i] = context.salesB3.tax_class[i];
                            data.prc_gen_item_cat_grp_code[i] = context.salesB3.gen_item_cat_grp[i];
                            data.prc_acc_ass_grp_code[i] = context.salesB3.account_assign_grp[i];
                            data.prc_item_cat_grp_code[i] = context.salesB3.item_cat_grp[i];
                            data.prc_trans_group_code[i] = context.salesB3.trans_group[i];
                            data.prc_loading_group_code[i] = context.salesB3.loading_group[i];
                            data.prc_profit_center_code[i] = context.salesB3.profit_center[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.mrpB3.abc_indicator.length; i++) {
                        if (context.mrpB3.abc_indicator[i] != '' &&
                            context.mrpB3.mrp_type[i] != '' &&
                            context.mrpB3.mrp_controller[i] != '' &&
                            context.mrpB3.lot_size[i] != '' &&
                            context.mrpB3.min[i] != '' &&
                            context.mrpB3.max[i] != '' &&
                            context.mrpB3.procurement_type[i] != '' &&
                            context.mrpB3.special_proc[i] != '') {
                            data.prc_abc_indicator_code[i] = context.mrpB3.abc_indicator[i];
                            data.prc_mrp_type_code[i] = context.mrpB3.mrp_type[i];
                            data.prc_mrp_controller_code[i] = context.mrpB3.mrp_controller[i];
                            data.prc_lot_size_code[i] = context.mrpB3.lot_size[i];
                            data.prc_min_stock[i] = context.mrpB3.min[i];
                            data.prc_max_stock[i] = context.mrpB3.max[i];
                            data.prc_procurement_type_code[i] = context.mrpB3.procurement_type[i];
                            data.prc_special_proc_type_code[i] = context.mrpB3.special_proc[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.accountingB3.valuation_class.length; i++) {
                        if (context.accountingB3.valuation_class[i] != '' &&
                            context.accountingB3.valuation_category[i] != '' &&
                            context.accountingB3.valuation_type[i] != '' &&
                            context.accountingB3.price_control[i] != '' &&
                            context.accountingB3.price_determination[i] != '' &&
                            context.accountingB3.price_unit[i] != '') {
                            data.prc_valuation_class_code[i] = context.accountingB3.valuation_class[i];
                            data.prc_valuation_category_code[i] = context.accountingB3.valuation_category[i];
                            data.prc_valuation_type_code[i] = context.accountingB3.valuation_type[i];
                            data.prc_price_control_code[i] = context.accountingB3.price_control[i];
                            data.prc_price_determination[i] = context.accountingB3.price_determination[i];
                            data.prc_standard_price[i] = context.accountingB3.price_unit[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListB2.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        context.draftList = response.data.data.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            toggleAccordion(show, type) {
                if (type == 'bdsA1') {
                    if (show == '') {
                        this.accordion.a1.bds.show = 'show'
                    } else {
                        this.accordion.a1.bds.show = ''
                    }
                } else if (type == 'mpnA1') {
                    if (show == '') {
                        this.accordion.a1.mpn.show = 'show'
                    } else {
                        this.accordion.a1.mpn.show = ''
                    }
                } else if (type == 'eqiA1') {
                    if (show == '') {
                        this.accordion.a1.eqi.show = 'show'
                    } else {
                        this.accordion.a1.eqi.show = ''
                    }
                } else if (type == 'adA1') {
                    if (show == '') {
                        this.accordion.a1.ad.show = 'show'
                    } else {
                        this.accordion.a1.ad.show = ''
                    }
                } else if (type == 'clA2') {
                    if (show == '') {
                        this.accordion.a2.cl.show = 'show'
                    } else {
                        this.accordion.a2.cl.show = ''
                    }
                } else if (type == 'plA2') {
                    if (show == '') {
                        this.accordion.a2.pl.show = 'show'
                    } else {
                        this.accordion.a2.pl.show = ''
                    }
                } else if (type == 'mpnA2') {
                    if (show == '') {
                        this.accordion.a2.mpn.show = 'show'
                    } else {
                        this.accordion.a2.mpn.show = ''
                    }
                } else if (type == 'eqiA2') {
                    if (show == '') {
                        this.accordion.a2.eqi.show = 'show'
                    } else {
                        this.accordion.a2.eqi.show = ''
                    }
                } else if (type == 'mpnA3') {
                    if (show == '') {
                        this.accordion.a3.mpn.show = 'show'
                    } else {
                        this.accordion.a3.mpn.show = ''
                    }
                } else if (type == 'eqiA3') {
                    if (show == '') {
                        this.accordion.a3.eqi.show = 'show'
                    } else {
                        this.accordion.a3.eqi.show = ''
                    }
                } else if (type == 'mpnA4') {
                    if (show == '') {
                        this.accordion.a4.mpn.show = 'show'
                    } else {
                        this.accordion.a4.mpn.show = ''
                    }
                } else if (type == 'eqiA4') {
                    if (show == '') {
                        this.accordion.a4.eqi.show = 'show'
                    } else {
                        this.accordion.a4.eqi.show = ''
                    }
                }
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
        }   
    };
</script>
<style></style>